<template>
  <div class="d-flex flex-column">
    <v-card
      :color="scssVariables.jColorBgBlock"
      variant="flat"
      class="j-promo-card h-100"
    >
      <general-flip-card>
        <template #front>
          <general-promo-card-parts-content :content-promo-card="promoCardData?.front" />
        </template>
        <template #back>
          <general-promo-card-parts-content :content-promo-card="promoCardData?.back" />
        </template>
      </general-flip-card>
    </v-card>
    <div class="d-flex flex-column">
      <v-btn
        variant="flat"
        size="x-large"
        :disabled="promoCardData.actionData.disableBtn"
        :class="[
          'text-none',
          'text-body-1',
          'py-4',
          'px-lg-5',
          'j-btn',
          {
            'mb-5': promoCardData.actionData.showVerificationEmail || promoCardData.actionData.showVerificationPhone,
            'j-btn--gradient-main': promoCardData.actionData.activateBtn,
          },
        ]"
        :color="scssVariables.jColorBtnRegular"
        @click="handleActionButtonClick()"
      >
        <general-text
          :class="!promoCardData.actionData.disableBtn ? 'j-text--white' : ''"
          :dictionary-key="actionButtonText"
        />
      </v-btn>
    </div>
    <general-promo-card-parts-verification-control
      v-if="promoCardData.actionData.showVerificationEmail"
      class="mt-1"
      :text="promoCardData.actionData.descriptionEmail"
      @click="handleVerificationControlClick(PROMOTION_DATA.confirmEmailText)"
    />
    <general-promo-card-parts-verification-control
      v-if="promoCardData.actionData.showVerificationPhone"
      class="mt-1"
      :text="promoCardData.actionData.descriptionPhone"
      @click="handleVerificationControlClick(PROMOTION_DATA.verifyPhoneText)"
    />
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { FormattedPromotionData } from '~/types/pages/cabinet';
import { PROMOTION_DATA } from '~/constants/general';

const scssVariables = useScssVariables();
const emit = defineEmits(['buttonClick', 'clickVerificationControl']);
const actionButtonText = computed(() => componentProps.promoCardData?.actionData.activateBtn
  ? 'general_activate'
  : 'general_cancel',
);
const handleActionButtonClick = () => emit('buttonClick', componentProps.promoCardData);
const handleVerificationControlClick = (eventType: string) => {
  if (componentProps.promoCardData?.actionData.showVerificationEmail
    || componentProps.promoCardData?.actionData.showVerificationPhone) {
    emit('clickVerificationControl', eventType);
  }
};

const componentProps = defineProps({
  promoCardData: {
    type: Object as PropType<FormattedPromotionData>,
    default: () => ({}),
  },
});
</script>
