<template>
  <v-sheet
    v-if="showSuccessBlock"
    :color="scssVariables.jColorBgBlockDark"
    class="pa-6 py-lg-10"
    rounded
  >
    <general-text
      dictionary-key="general_done"
      class="j-text--fs-32 j-text--green mb-6 text-center"
    />
    <general-text
      dictionary-key="cabinet_verification_docs_been_sent"
      class="text-center"
    />
    <general-text
      dictionary-key="cabinet_verification_in_case_of_questions"
      class="mb-6 text-center"
    />
    <v-btn
      block
      variant="flat"
      size="large"
      :color="scssVariables.jColorBtnRegular"
      height="40"
      min-width="68"
      class="text-none text-body-1 j-btn mx-auto"
      @click="showSuccessBlock = false"
    >
      {{ $t('general_ok') }}
    </v-btn>
  </v-sheet>
  <j-page-cabinet-two-columns v-else>
    <template #left>
      <j-page-cabinet-parts-verification-block v-model="showSuccessBlock" />
    </template>
    <template #right>
      <j-page-cabinet-parts-player-status />
    </template>
  </j-page-cabinet-two-columns>
</template>
<script setup lang="ts">
const scssVariables = useScssVariables();
const showSuccessBlock = ref(false);
</script>
