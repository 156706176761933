<template>
  <v-form>
    <div class="j-file-input-wrapper rounded pa-6">
      <general-text
        dictionary-key="cabinet_verification_upload_documents"
        class="j-text--fs-16 j-text--green text-center font-weight-medium mb-2"
      />
      <general-text
        :text="$t('cabinet_verification_format_file_size', {
          formats: allowedExtensions.join(', '),
          size: fileMaxSizeMb,
        })"
        class="text-center mb-6"
      />
      <v-file-input
        v-model="rawInputFiles"
        class="j-file-input mx-auto"
        :label="$t('general_add_files')"
        multiple
        :clearable="false"
        hide-details
        prepend-icon=""
        :bg-color="scssVariables.jColorBtnRegular"
        density="compact"
        flat
        single-line
        variant="solo"
        @change="handleFilesUpload"
      />
    </div>
    <div
      v-if="processedFilesData.length"
      class="mt-6"
    >
      <div
        v-for="(processedFileDataItem, index) in processedFilesData"
        :key="index"
      >
        <v-progress-linear
          :model-value="processedFileDataItem.progress"
          class="mt-4 rounded"
          height="32"
          :striped="processedFileDataItem.progress === 99"
          :bg-color="getProgressBgColor(processedFileDataItem.error)"
          :color="getProgressColor(processedFileDataItem.progress)"
          bg-opacity="1"
        >
          <div class="d-flex justify-space-between align-center w-100 px-4">
            <span />
            <general-text
              :text="processedFileDataItem.file.name"
              :class="[
                'j-text--white',
                {
                  'j-text--error': processedFileDataItem.error,
                  'j-text--green': processedFileDataItem.progress === 100,
                },
              ]"
            />
            <general-text
              :text="`${processedFileDataItem.progress}%`"
              :class="[
                'j-text--white',
                { 'j-text--error': processedFileDataItem.error },
              ]"
            />
          </div>
        </v-progress-linear>
        <div
          v-if="processedFileDataItem.error"
          class="d-flex mt-3"
        >
          <v-img
            :src="images['progress-bar-error']"
            alt="icon progress-bar-error"
            :max-width="16"
            :width="16"
            :height="16"
          />
          <general-text
            :text="processedFileDataItem.error"
            class="j-text--error j-text--fs-12 ml-2"
          />
        </div>
      </div>
    </div>
  </v-form>
</template>

<script setup lang="ts">
/**
 *	This component handles file uploading and displays the progress of each file upload.
 *
 *	@component
 *	@prop {Function} uploadFiles - Function to upload files through API.
 *	@prop {Number} fileMaxSizeMb - Maximum file size in MB.
 *	@prop {Array} allowedExtensions - Allowed files extensions.
 */
import type { CustomFile } from '~/types/components/General/FileUploader';
import type { ResponseError } from '~/types/general/globals';

const componentProps = defineProps({
  uploadFiles: {
    type: Function,
    default: () => {},
  },
  fileMaxSizeMb: {
    type: Number,
    default: null,
  },
  allowedExtensions: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
});
const { t } = useI18n();
const allowedExtensions = computed(() => componentProps.allowedExtensions.length
  ? componentProps.allowedExtensions
  : ['jpg', 'pdf', 'png', 'gif', 'jpeg']);
const fileMaxSizeMb = computed(() => componentProps.fileMaxSizeMb || 10);
const scssVariables = useScssVariables();
const images = useAssetsImages();
const rawInputFiles = ref([]);
const processedFilesData = ref<CustomFile[]>([]);
const filesDataForUpload = ref<CustomFile[]>([]);
/**
 * Validates the files selected for upload.
 * - Checks if the files size exceeds the maximum allowed size;
 * - Checks if the files extension is allowed;
 * - Checks for duplicate files with already uploaded;
 * - Generates files data list for upload from raw input files
 */
const validateFilesForUpload = () => {
  const fileMaxSizeInBytes = fileMaxSizeMb.value * 1024 * 1024;

  filesDataForUpload.value = rawInputFiles.value.map((file) => ({
    file,
    error: '',
    progress: 0,
  }));
  rawInputFiles.value = [];
  for (const fileDataItem of filesDataForUpload.value) {
    if (fileDataItem.file.size > fileMaxSizeInBytes) {
      fileDataItem.error = t('error_invalid_file_size', { size: fileMaxSizeMb.value });
      continue;
    }
    const extension = fileDataItem.file.name.split('.').pop()?.toLowerCase() ?? '';

    if (!allowedExtensions.value.includes(extension)) {
      fileDataItem.error = t('error_invalid_document_type');
      continue;
    }
    const isDuplicate = Boolean(
      processedFilesData.value.filter(
        (processedFileDataItem) => processedFileDataItem.file.name === fileDataItem.file.name,
      ).length,
    );

    if (isDuplicate) {
      fileDataItem.error = t('error_invalid_unique_file');
    }
  }
};
/**
 * Starts the file upload process for the validated files.
 * - Simulates file upload progress in UI;
 * - Calls the uploadFiles function to upload the files on server;
 * - Handles errors during the upload process;
 * - Updated processed files data list with current uploaded files list;
 */
const startFilesUpload = () => {
  filesDataForUpload.value.forEach(async (fileDataItem) => {
    if (!fileDataItem.error) {
      const formData = new FormData();
      let isStopLoadingProgressSimulation = false;
      const simulateFileLoadingProgress = () => {
        if (fileDataItem.progress < 99 && !isStopLoadingProgressSimulation) {
          fileDataItem.progress = Math.min(fileDataItem.progress + 1, 99);
          requestAnimationFrame(simulateFileLoadingProgress);
        }
      };

      requestAnimationFrame(simulateFileLoadingProgress);
      formData.append('file', fileDataItem.file);
      try {
        await componentProps.uploadFiles(formData);
        fileDataItem.progress = 100;
        emit('fileLoaded');
      } catch (error) {
        const responseError = error as ResponseError;

        isStopLoadingProgressSimulation = true;
        switch(responseError?.data?.items && responseError?.data?.items[0].message) {
          case 'Document already contains same file':
            fileDataItem.error = t('error_invalid_unique_file');
            break;
          default:
            fileDataItem.error = t('error_file_upload');
            break;
        }

        fileDataItem.progress = 0;
      }
    }
  });

  processedFilesData.value.push(...filesDataForUpload.value);
};
/**
 * Returns the color for the progress bar based on the file's upload progress
 * To ensure that progress bar is visible only when file uploading, in other cases progress bar is transparent.
 *
 * @param {number} progress - The upload progress percentage.
 * @returns {string} The color for the progress bar.
 */
const getProgressColor = (progress: number) => {
  return progress === 0 || progress === 100
    ? 'transparent'
    : scssVariables.jColorGreen;
};
/**
 * Returns the background color for the uploaded file bar. If upload successful then success background used
 * else error color used for upload file bar
 *
 * @param {string} error - The error message if any.
 * @returns {string} The background color for the progress bar.
 */
const getProgressBgColor = (error: string) => {
  return error
    ? scssVariables.jColorBgProgressLinearError
    : scssVariables.jColorBtnRegular;
};
/**
 * Handles the file input change event to start the upload process.
 */
const handleFilesUpload = () => {
  emit('startFilesUpload');
  validateFilesForUpload();
  startFilesUpload();
};

const emit = defineEmits([
  'fileLoaded',
  'startFilesUpload',
]);
</script>

<style scoped lang="scss">
.j-file-input-wrapper {
  border: 1px solid $j-color-green;
}
.j-file-input {
  max-width: 180px;
  :deep(.v-field--active .v-label.v-field-label) {
    visibility: initial;
  }
  :deep(.v-field--center-affix .v-label.v-field-label) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  :deep(.v-label.v-field-label) {
    margin: 0;
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
