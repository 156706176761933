<template>
  <div class="j-verification-control d-flex flex-wrap justify-space-between align-center px-2 py-1">
    <nuxt-link class="j-link j-link--main-gradient">
      <general-text :dictionary-key="text" />
    </nuxt-link>
    <v-icon :color="scssVariables.jColorBtnGradientMainFallBack">
      mdi-clock-outline
    </v-icon>
  </div>
</template>

<script setup lang="ts">
const scssVariables = useScssVariables();

defineProps({
  text: {
    type: String,
    default: '',
  },
});
</script>

<style lang="scss" scoped>
.j-verification-control {
  background-color: $j-color-bg-block;
}
</style>
