<template>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pt-6 pb-4 px-4 pa-md-6"
    rounded
  >
    <v-form
      v-if="!getUserData.profileFilled"
      ref="personalDataForm"
      :disabled="loading"
      class="j-personal-data-form"
      validate-on="submit"
      @submit.prevent
    >
      <general-text
        dictionary-key="cabinet_personal_data_your_input_data"
        class="j-text--white j-text--fs-16 font-weight-medium mb-6 mb-md-8 j-personal-data-form__w-md-38p"
      />
      <general-text-field
        v-model.trim="personalData.screenName"
        :label="$t('cabinet_personal_data_nickname')"
        class="mb-4 mb-md-6 j-personal-data-form__w-md-38p"
        :rules="[
          required,
          nickname,
        ]"
        :max-length="17"
      />
      <div class="d-flex flex-column flex-md-row">
        <general-text-field
          v-model.trim="personalData.firstName"
          :label="$t('cabinet_personal_data_name')"
          class="mr-md-12 mb-4 mb-md-6 j-personal-data-form__w-md-38p"
          :rules="[
            required,
            name,
          ]"
        />
        <general-text-field
          v-model.trim="personalData.lastName"
          :label="$t('cabinet_personal_data_last_name')"
          class="mb-4 mb-md-6 j-personal-data-form__w-md-38p"
          :rules="[
            required,
            lastName,
          ]"
        />
      </div>
      <div class="d-flex flex-column flex-md-row">
        <general-select
          v-model="personalData.country"
          :items="countries"
          :item-title="`name_short_${getUserData.locale}`"
          item-value="code_alpha2"
          :label="$t('cabinet_personal_data_country')"
          :placeholder="$t('cabinet_personal_data_select_country')"
          class="mr-md-12 mb-4 mb-md-6 j-personal-data-form__w-md-38p"
          :rules="[requiredCountry]"
          @update:model-value="setPhoneCode"
        >
          <template #item="data">
            <v-list-subheader v-if="data.props.header">
              {{ data.props.header }}
            </v-list-subheader>
            <v-divider v-else-if="data.props.divider" />
            <v-list-item
              v-else
              v-bind="data.props"
            />
          </template>
        </general-select>
        <general-text-field
          v-model.trim="personalData.city"
          :label="$t('cabinet_personal_data_city')"
          class="mb-4 mb-md-6 j-personal-data-form__w-md-38p"
          :rules="[
            required,
            city,
          ]"
        />
      </div>
      <div class="mb-4 mb-md-6 j-personal-data-form__w-md-38p">
        <general-datepicker
          v-model="personalData.birthday"
          :label="$t('cabinet_personal_data_birthday')"
          :rules="[requiredBirthday]"
          min="1940"
          :max="getEighteenYearsAgo"
          :error-messages="errorMessageSubmitPersonalFormBirthday"
          @update:model-value="clearSubmitPersonalFormBirthdayError"
        />
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-8 mb-6"
      />
      <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
        <general-text-field
          v-model="userEmail"
          :label="$t('general_email')"
          class="j-personal-data-form__w-md-38p"
          disabled
        />
        <div
          v-if="getUserData.emailConfirmed"
          class="d-flex mt-4"
        >
          <general-text
            :text="getUserData.email"
            class="mr-1 d-none d-md-block"
          />
          <div class="d-flex">
            <v-img
              :src="images['confirmedIcon']"
              max-width="20"
              width="20"
              height="20"
              class="ml-md-4 mr-md-0 mr-2 order-md-1"
              alt="icon confirmed"
            />
            <general-text
              dictionary-key="general_confirmed"
              class="j-text--confirmed-gradient"
            />
          </div>
        </div>
        <nuxt-link
          v-else
          class="j-link j-link--main-gradient j-link--fs-14 mt-4"
          @click="useConfirmationEmail"
        >
          {{ $t('general_confirm') }}
        </nuxt-link>
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-4 mb-6 mt-md-6"
      />
      <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
        <general-text-field
          v-model="personalData.phone"
          :label="$t('cabinet_personal_data_phone')"
          class="mr-md-12 j-personal-data-form__w-md-38p"
          :rules="[required, phoneFirstDigit, phoneLength]"
          :error-messages="errorMessageSubmitPersonalFormPhone"
          @input="phoneHandler"
        />
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-6 mb-8"
      />
      <v-btn
        :loading="loading"
        variant="flat"
        size="large"
        :color="scssVariables.jColorBtnRegular"
        class="j-btn text-none text-body-1 px-6"
        height="54"
        :width="isXsDisplay ? '100%' : '132px'"
        @click="submitPersonalDataForm"
      >
        {{ $t('general_save') }}
      </v-btn>
    </v-form>
    <div
      v-else
      class="j-personal-data-form"
    >
      <general-text
        dictionary-key="cabinet_personal_data_profile_data"
        class="j-text--white j-text--fs-16 font-weight-medium mb-7 mb-md-9 j-personal-data-form__w-md-38p"
      />
      <div class="mb-5 mb-md-7 j-personal-data-form__w-md-38p">
        <general-text
          dictionary-key="cabinet_personal_data_nickname"
          class="j-text--fs-12 mb-1"
        />
        <general-text
          :text="getUserData.screenName"
          class="j-text--white"
        />
      </div>
      <div class="d-flex flex-column flex-md-row">
        <div class="mr-md-12 mb-5 mb-md-7 j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="cabinet_personal_data_name"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getUserData.firstName"
            class="j-text--white"
          />
        </div>
        <div class="mb-5 mb-md-7 j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="cabinet_personal_data_last_name"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getUserData.lastName"
            class="j-text--white"
          />
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row">
        <div class="mr-md-12 mb-5 mb-md-7 j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="cabinet_personal_data_country"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getCountryByCodeAlpha2"
            class="j-text--white"
          />
        </div>
        <div class="mb-5 mb-md-7 j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="cabinet_personal_data_city"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getUserData.city"
            class="j-text--white"
          />
        </div>
      </div>
      <div class="mb-5 mb-md-7 j-personal-data-form__w-md-38p">
        <general-text
          dictionary-key="cabinet_personal_data_birthday"
          class="j-text--fs-12 mb-1"
        />
        <general-text
          :text="useFormatDate(getUserData.birthday ?? '', FORMAT_DATE.DOT_DATE)"
          class="j-text--white"
        />
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-8 mb-6"
      />
      <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
        <div class="j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="general_email"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getUserData.email"
            class="j-text--white"
          />
        </div>
        <div
          v-if="getUserData.emailConfirmed"
          class="d-flex mt-4"
        >
          <general-text
            :text="getUserData.email"
            class="mr-1 d-none d-md-block"
          />
          <div class="d-flex">
            <v-img
              :src="images['confirmedIcon']"
              max-width="20"
              width="20"
              height="20"
              class="ml-md-4 mr-md-0 mr-2 order-md-1"
              alt="icon confirmed"
            />
            <general-text
              dictionary-key="general_confirmed"
              class="j-text--confirmed-gradient"
            />
          </div>
        </div>
        <nuxt-link
          v-else
          class="j-link j-link--main-gradient j-link--fs-14 mt-4"
          @click="useConfirmationEmail"
        >
          {{ $t('general_confirm') }}
        </nuxt-link>
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-4 mb-6 mt-md-6"
      />
      <div class="d-flex flex-column flex-md-row justify-space-between align-md-center">
        <div class="mr-md-12 j-personal-data-form__w-md-38p">
          <general-text
            dictionary-key="cabinet_personal_data_phone"
            class="j-text--fs-12 mb-1"
          />
          <general-text
            :text="getUserData.phone"
            class="j-text--white"
          />
        </div>
        <div
          v-if="getUserData.phoneConfirmed"
          class="d-flex mt-4"
        >
          <general-text
            dictionary-key="cabinet_personal_data_phone_number"
            class="mr-1 d-none d-md-block"
          />
          <div class="d-flex">
            <v-img
              :src="images['confirmedIcon']"
              max-width="20"
              width="20"
              height="20"
              class="ml-md-4 mr-md-0 mr-2 order-md-1"
              alt="icon confirmed"
            />
            <general-text
              dictionary-key="general_confirmed"
              class="j-text--confirmed-gradient"
            />
          </div>
        </div>
        <template v-else>
          <div
            v-if="!isSendSmsCode"
            class="d-flex align-center mt-4"
          >
            <nuxt-link
              class="j-link j-link--main-gradient j-link--fs-14 mr-1"
              @click="confirmPhone"
            >
              {{ $t('general_send') }}
            </nuxt-link>
            <general-text
              dictionary-key="cabinet_personal_data_send_sms"
              class="j-text text-no-wrap"
            />
          </div>
          <v-form
            v-else
            ref="confirmationCodeForm"
            :disabled="loading"
            class="d-flex align-center
            justify-md-end j-personal-data-form__w-md-34pp"
            validate-on="submit"
            @submit.prevent
          >
            <general-text-field
              v-model.trim="confirmationCodeData"
              class="mr-6 j-personal-data-form__w-md-53p j-personal-data-form__w-42p"
              :label="$t('cabinet_personal_data_enter_code')"
              :error-messages="errorMessageConfirmationCodeForm"
              :rules="[required, confirmationCode]"
              @update:model-value="clearConfirmationCodeFormError"
            />
            <div class="mt-4">
              <nuxt-link
                class="j-link j-link--main-gradient j-link--fs-14"
                @click="sendSmsCode"
              >
                {{ $t('general_send') }}
              </nuxt-link>
            </div>
          </v-form>
        </template>
      </div>
      <general-text
        v-if="errorMessageConfirmationCodePhone && !getUserData.phoneConfirmed"
        :text="errorMessageConfirmationCodePhone"
        class="j-text--error j-text--fs-12 pt-3"
      />
    </div>
  </v-sheet>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';
import { useCashStore } from '~/stores/cash';
import type { ResponseError } from '~/types/general/globals';
import type { VuetifyForm } from '~/types/general/vuetify';
import type {
  PersonalData,
  CountriesItem,
} from '~/types/pages/cabinet';
import {
  COUNTRIES,
  FORMAT_DATE,
} from '~/constants/general';
import {
  LOCAL_STORAGE,
  SESSION_STORAGE,
} from '~/constants/storedPropertiesNames';
import { AVAILABLE_COUNTRIES } from '../constants';

const personalDataForm: Ref<null | VuetifyForm> = ref(null);
const confirmationCodeForm: Ref<null | VuetifyForm> = ref(null);
const scssVariables = useScssVariables();
const images = useAssetsImages();
const loading = ref(false);
const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const cabinetStore = useCabinetStore();
const {
  savePlayerData,
  sendConfirmationSms,
  validateConfirmationSms,
} = cabinetStore;
const globalsStore = useGlobalsStore();
const { setNotification } = globalsStore;
const cashStore = useCashStore();
const { activateCash } = cashStore;
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const personalData: Ref<PersonalData> = ref({
  birthday: undefined,
  city: '',
  country: undefined,
  firstName: '',
  lastName: '',
  phone: '',
  screenName: '',
});
const confirmationCodeData = ref('');
const userEmail = ref(getUserData.value.email);
const isSendSmsCode = ref(false);
const { t } = useI18n();
const alphabetSortedCountries = computed(
  () => COUNTRIES.sort((a, b) => -1 * String(b[`name_short_${getUserData.value.locale}` as keyof CountriesItem])
    .localeCompare(String(a[`name_short_${getUserData.value.locale}` as keyof CountriesItem]))),
);
const popularCountries = computed(() =>
alphabetSortedCountries.value.filter((country) => country.popular));
const availableCountries = computed(() =>
alphabetSortedCountries.value.filter((country) => AVAILABLE_COUNTRIES.includes(country.code_alpha2)));
const countries = ref([
  { props: { header: t('cabinet_personal_data_popular') } },
  ...popularCountries.value,
  { props: { header: t('cabinet_personal_data_all_countries') } },
  ...availableCountries.value,
]);
const {
  required,
  nickname,
  name,
  lastName,
  requiredCountry,
  city,
  requiredBirthday,
  phoneFirstDigit,
  phoneLength,
  confirmationCode,
} = useValidationRules();

const phoneHandler = (event: { target: { value: string; }; }) => {
  clearSubmitPersonalFormPhoneError();
  const inputValue = event.target.value;
  const numericValue = inputValue.replace(/\D/g, '');

  personalData.value.phone = numericValue;
};

const setPhoneCode = (codeAlpha2: string) => {
  const selectedCountryFiltered = countries.value.filter((country) =>
  country.hasOwnProperty('code_alpha2')) as CountriesItem[];
  const selectedCountry = selectedCountryFiltered.find((country) =>
  country.code_alpha2 === codeAlpha2);

  personalData.value.phone = String(selectedCountry?.phone_code) ?? '';
};
const getEighteenYearsAgo = computed(() => {
  const currentDate = new Date();
  const ageInYears = 18;
  const eighteenYearsAgo = new Date(currentDate);

  eighteenYearsAgo.setFullYear(currentDate.getFullYear() - ageInYears);

  return eighteenYearsAgo;
});
const getCountryByCodeAlpha2 = computed(() => {
  if (getUserData.value.registrationCountry) {
    const selectedCountryFiltered = countries.value.filter((country) =>
      country.hasOwnProperty('code_alpha2'),
    ) as CountriesItem[];

    const selectedCountry = selectedCountryFiltered.find(
      (country) =>
        country.code_alpha2 === getUserData.value.registrationCountry,
    );

    return selectedCountry
      ? String(selectedCountry[
          `name_short_${getUserData.value.locale}` as keyof CountriesItem
        ])
      : '';
  }

  return '';
});

const confirmPhone = async () => {
  loading.value = true;
  const responseError = await sendConfirmationSms();

  if (responseError) {
    switch (responseError) {
      case 4006:
        errorMessageConfirmationCodePhone.value = t('cabinet_personal_data_error_4006');
        break;
      case 4023:
        setNotification({ dictionaryKeyText: 'cabinet_personal_data_error_4023' });
        break;
      case 4024:
        setNotification({ dictionaryKeyText: 'cabinet_personal_data_error_4024' });
        break;
      case 4025:
        setNotification({ dictionaryKeyText: 'cabinet_personal_data_error_4025' });
        break;
      default:
        errorMessageConfirmationCodePhone.value = '';
    }
  }

  loading.value = false;
  checkCodeValidityTime();
};
const errorMessageConfirmationCodeForm = ref('');
const sendSmsCode = async () => {
  const isValidForm = await confirmationCodeForm.value?.validate();

  if (isValidForm?.valid) {
      loading.value = true;
      const responseError = await validateConfirmationSms(confirmationCodeData.value);

      if (responseError) {
        switch (responseError) {
          case 4026:
            errorMessageConfirmationCodeForm.value = t('cabinet_personal_data_error_4026');
            break;
          case 4027:
            errorMessageConfirmationCodeForm.value = t('cabinet_personal_data_error_4027');
            break;
          default:
            clearConfirmationCodeFormError();
        }
      }

      loading.value = false;
  }
};
const errorMessageConfirmationCodePhone = ref('');
const errorMessageSubmitPersonalFormPhone = ref('');
const errorMessageSubmitPersonalFormBirthday = ref('');
const submitPersonalDataForm = async () => {
  const isValidForm = await personalDataForm.value?.validate();

  if (isValidForm?.valid) {
    loading.value = true;
    try {
      const formattedPersonalDate = {
        ...personalData.value,
        birthday: personalData.value.birthday && formatDate(personalData.value.birthday),
      };

      await savePlayerData(formattedPersonalDate);
      const openCashModal = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.openCashModal) as string);

      if (openCashModal) {
        activateCash();
      }
    } catch (error) {
      const responseError = error as ResponseError;

      switch (responseError.data?.return?.errorCode) {
        case 4006:
          errorMessageSubmitPersonalFormPhone.value = t('cabinet_personal_data_error_4006');
          break;
        case 4007:
          errorMessageSubmitPersonalFormBirthday.value = t('cabinet_personal_data_error_4007');
          break;
        default:
          clearSubmitPersonalFormPhoneError();
          clearSubmitPersonalFormBirthdayError();
      }
    }
    loading.value = false;
  }
};
const checkCodeValidityTime = () => {
  const codeValidityInMilliseconds =
    Number(localStorage.getItem(LOCAL_STORAGE.codeValidityInMilliseconds) as string);
  const currentDate = new Date();
  const currentDateMilliseconds = currentDate.getTime();

  if (codeValidityInMilliseconds && (currentDateMilliseconds < codeValidityInMilliseconds)) {
    const delayTime = codeValidityInMilliseconds - currentDateMilliseconds;

    isSendSmsCode.value = true;

    setTimeout(() => {
      isSendSmsCode.value = false;
      localStorage.removeItem(LOCAL_STORAGE.codeValidityInMilliseconds);
    }, delayTime);
  } else {
    localStorage.removeItem(LOCAL_STORAGE.codeValidityInMilliseconds);
  }
};
const formatDate = (date: Date[]) => {
  if (!Array.isArray(date)) {
    return useFormatDate(String(new Date(new Date(date).setHours(12))), FORMAT_DATE.ISO_DATE);
  }
};
const clearConfirmationCodeFormError = () => errorMessageConfirmationCodeForm.value = '';
const clearSubmitPersonalFormPhoneError = () => errorMessageSubmitPersonalFormPhone.value = '';
const clearSubmitPersonalFormBirthdayError = () => errorMessageSubmitPersonalFormBirthday.value = '';

onMounted(() => {
  checkCodeValidityTime();
});
</script>
<style lang="scss" scoped>
.j-personal-data-form {
  .j-personal-data-form__w-42p {
    max-width: 42%;
    width: 100%;
  }
  @media(min-width: 960px) {
    .j-personal-data-form__w-md-34p {
      max-width: 34%;
      width: 100%;
    }
    .j-personal-data-form__w-md-38p {
      max-width: 38%;
      width: 100%;
    }
    .j-personal-data-form__w-md-53p {
      max-width: 53%;
      width: 100%;
    }
  }
}
</style>
