<template>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pa-4 pa-lg-6"
    rounded
  >
    <general-text
      dictionary-key="cabinet_verification_two_steps"
      class="j-text--fs-16 j-text--white mb-4"
    />
    <general-text-list
      :items-list="
        addIndexForLocalization('cabinet_verification_two_steps_list')
      "
      class="j-text--line-height-24"
      not-marked
      no-items-gap
    />
  </v-sheet>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pa-4 pa-lg-6 mt-2"
    rounded
  >
    <general-expansion-panels :items="VERIFICATION.identityConfirmation" />
  </v-sheet>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pa-4 pa-lg-6 mt-2"
    rounded
  >
    <general-expansion-panels :items="VERIFICATION.paymentMethodConfirmation">
      <template #text>
        <general-expansion-panels
          :items="VERIFICATION.paymentMethodConfirmationSub"
          class="px-4"
        />
      </template>
    </general-expansion-panels>
  </v-sheet>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pa-4 pa-lg-6 mt-2"
    rounded
  >
    <general-text
      dictionary-key="cabinet_verification_my_documents"
      class="j-text--fs-16 j-text--white"
    />
    <template v-if="getDocuments.length">
      <div
        v-for="document of getDocuments"
        :key="document.id"
      >
        <div class="d-flex justify-space-between flex-column flex-md-row pt-4 pb-3">
          <general-text
            :dictionary-key="
              $t(
                `cabinet_verification_document_${document.title.replace('-', '_')}`,
                { code: document.cardNumber },
              )
            "
          />
          <div class="d-flex align-center">
            <general-text
              :dictionary-key="
                $t(`cabinet_verification_document_${document.status}`)
              "
              :class="`j-text--${getDocumentColor(document.document_state_id)}`"
            />
            <general-tooltip v-if="document.statusMessageId">
              <template #activator="{ props }">
                <v-icon
                  v-bind="props"
                  class="ml-1"
                  icon="custom:tooltipIconActive"
                />
              </template>
              {{ $t(`cabinet_verification_status_message_id_${document.statusMessageId}`) }}
            </general-tooltip>
          </div>
        </div>
        <v-divider
          :color="scssVariables.jColorBgBlockLight"
          class="border-opacity-100"
        />
      </div>
    </template>
    <general-file-uploader
      class="mt-6"
      :upload-files="sendDocument"
      @file-loaded="fileLoadedHandler"
      @start-files-upload="fileLoadingHandler"
    />
    <v-btn
      v-if="showSuccessBtn"
      :disabled="isDisabledSuccessBtn"
      variant="flat"
      size="large"
      :color="scssVariables.jColorBtnRegular"
      height="40"
      min-width="68"
      block
      class="text-none text-body-1 j-btn mt-6 mx-auto mx-lg-0"
      @click="emit('update:modelValue', true)"
    >
      {{ $t('general_ok') }}
    </v-btn>
  </v-sheet>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useCabinetStore } from '~/stores/cabinet';
import {
  VERIFICATION,
  DOCUMENT_STATUSES,
  DOCUMENT_TYPES_ID,
} from '../constants';

defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const scssVariables = useScssVariables();
const cabinetStore = useCabinetStore();
const { getDocuments } = storeToRefs(cabinetStore);
const showSuccessBtn = ref(false);
const {
  fetchDocuments,
  sendDocument,
  setDocument,
} = cabinetStore;
const getDocumentColor = computed(() => {
  return (stateId: number) => {
    return (
      Object.values(DOCUMENT_STATUSES).find((status) => status.id === stateId)
        ?.color || DOCUMENT_STATUSES.inProgress.color
    );
  };
});
const isDisabledSuccessBtn = ref(false);
const fileLoadingHandler = () => (isDisabledSuccessBtn.value = true);
const fileLoadedHandler = () => {
  setDocument({
    id: Date.now(),
    title: 'other',
    status: 'in_progress',
    statusMessageId: null,
    document_type_id: DOCUMENT_STATUSES.inProgress.id,
    document_state_id: DOCUMENT_TYPES_ID.other,
    card_id: null,
  });
  showSuccessBtn.value = true;
  isDisabledSuccessBtn.value = false;
};

onMounted(async () => {
  nextTick(async () => {
    await fetchDocuments();
  });
});

const emit = defineEmits(['update:modelValue']);
</script>
