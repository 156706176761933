<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    @update:model-value="menuHandler"
  >
    <template #activator="{ props }">
      <v-responsive class="j-text-field text-start">
        <general-text
          v-if="componentProps.label"
          :text="componentProps.label!"
          class="j-text--fs-12 mb-1"
        />
        <v-text-field
          v-bind="props"
          ref="datepickerTextField"
          :model-value="formattedDate"
          hide-details="auto"
          variant="solo"
          :bg-color="scssVariables.jColorInputBg"
          rounded="4"
          density="compact"
          :error-messages="componentProps.errorMessages"
          :rules="(componentProps.rules as string[])"
          readonly
          append-inner-icon="custom:calendarIcon"
          :prefix="componentProps.prefix"
        />
      </v-responsive>
    </template>
    <v-date-picker
      v-model="selectedDate"
      class="j-datepicker"
      :color="color"
      :bg-color="bgColor"
      rounded
      title=""
      :min="componentProps.min"
      :max="componentProps.max"
      :multiple="componentProps.multiple"
      :year="year"
      @update:model-value="updateModelValue"
    />
  </v-menu>
</template>

<script setup lang="ts">
import { FORMAT_DATE } from '~/constants/general';
import type { VuetifyFormControl } from '~/types/general/vuetify';

const componentProps = defineProps({
  label: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '',
  },
  bgColor: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    default: () => [] as Date[],
  },
  errorMessages: {
    type: String,
    default: '',
  },
  rules: {
    type: Array,
    default: () => [],
  },
  min: {
    type: [String, Number, Date],
    default: '',
  },
  max: {
    type: [String, Number, Date],
    default: '',
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: '',
  },
});
const datepickerTextField = ref<null | VuetifyFormControl>(null);
const scssVariables = useScssVariables();
const color = computed(() => componentProps.color || scssVariables.jColorPrimary);
const bgColor = computed(() => componentProps.bgColor || scssVariables.jColorBgBlockLight);
const emit = defineEmits(['update:modelValue']);
/**
 * Counter to keep track of date selection attempts.
 * @type {Ref<number>}
 */
const counter = ref(0);
/**
 * Update the model value with the selected date(s).
 * If multiple dates are allowed, ensures the dates are ordered and within the limit.
 * @param {Date|readonly Date[]|unknown} modelValue - The selected date(s).
 */
const updateModelValue = (modelValue: Date | readonly Date[] | unknown) => {
  if (componentProps.multiple) {
    const dates = (modelValue as Date[]);

    ++counter.value;

    if (dates.length > 2) {
      dates.splice(0, dates.length - 2);
    }

    if (counter.value === 2 && dates.length) {
      isMenuOpen.value = false;
      counter.value = 0;
      const dateFrom = new Date(dates[0]);
      const dateTo = new Date(dates[1]);

      if (dateFrom > dateTo || dateTo < dateFrom) {
        dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      }

      emit('update:modelValue', dates);
      componentProps.rules.length && datepickerTextField.value?.resetValidation();
    }

    if (!dates.length) {
      isMenuOpen.value = false;
      counter.value = 0;
      selectedDate.value = prevDate.value;
      emit('update:modelValue', prevDate.value);
      componentProps.rules.length && datepickerTextField.value?.resetValidation();
    } else {
      prevDate.value = dates;
    }
  } else {
    emit('update:modelValue', modelValue);
    componentProps.rules.length && datepickerTextField.value?.resetValidation();
    isMenuOpen.value = false;
  }
};
const isMenuOpen = ref(false);
/**
 * Keeps track of the previously selected date(s).
 * This is used to revert the selection if the user clears the date selection or cancels the input.
 * @type {Ref<Date[]>}
 */
const prevDate = ref<Date[]>([]);
const selectedDate = ref(componentProps.modelValue as Date[]);
/**
 * Handle the menu open/close events.
 * If the menu is closed and multiple dates are allowed, ensures that the selected dates are properly ordered
 * and updates the model value.
 *
 * @param {boolean} event - Menu open state.
 */
const menuHandler = (event: boolean) => {
  if (!event) {
    if (counter.value && componentProps.multiple) {
      const dateFrom = new Date(selectedDate.value[0]);
      const dateTo = new Date(selectedDate.value[1]);

      if (dateFrom > dateTo || dateTo < dateFrom) {
        selectedDate.value.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      }
    }
    counter.value = 0;
    emit('update:modelValue', selectedDate.value);
    componentProps.rules.length && datepickerTextField.value?.resetValidation();
  }
};
/**
 * Computes the formatted date(s) for display in the text field.
 * If multiple dates are selected, formats and joins them with a separator.
 * @returns {string} The formatted date string.
 */
const formattedDate = computed(() => {
  if (selectedDate.value) {
    if (!Array.isArray(selectedDate.value)) {
      const date = new Date(selectedDate.value).getTime();

      return useFormatDate(date, FORMAT_DATE.DOT_DATE);
    } else if (selectedDate.value.length > 0) {
      return selectedDate.value
        .map((item) => {
          const date = new Date(item as Date).getTime();

          return useFormatDate(date, FORMAT_DATE.DOT_DATE);
        })
        .join(' - ');
    }
  }

  return selectedDate.value;
});
/**
 * Computes the year from the maximum date.
 * @returns {number|undefined} The year extracted from the maximum date, or undefined if not set.
 */
const year = computed(() => componentProps.max
  ? new Date(componentProps.max).getFullYear()
  : undefined,
);
</script>
<style lang="scss" scoped>
.j-datepicker {
  :deep(.v-picker-title) {
    display: none;
  }
  :deep(.v-picker__header) {
    display: none;
  }
}
.j-text-field {
  :deep(.v-input) {
    .v-field {
      border: 1px solid transparent
    }
    .v-field__append-inner {
      .v-icon {
        opacity: 1;
      }
    }
    .v-text-field__prefix {
      font-size: $j-form-control-font-size;
      color: $j-color-text-secondary;
      opacity: 1;
      align-self: center;
    }
    .v-field__input {
      font-size: $j-form-control-font-size;
    }
    .v-input__details {
      padding: 12px 0 0;
    }
  }
  :deep(.v-input--error:not(.v-input--disabled)) {
    .v-field {
      border-color: $j-color-error
    }
    .v-field__input {
      color: $j-color-error;
    }
    .v-input__details .v-messages {
      color: $j-color-error;
      .v-messages__message {
        line-height: 16px;
      }
    }
  }
}
</style>