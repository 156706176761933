<template>
  <v-dialog
    class="j-modal"
    :model-value="componentProps.modelValue"
    content-class="j-modal__content"
    :width="width"
    max-width="calc(100% - 32px)"
    max-height="calc(100% - 32px)"
    :scrim="scssVariables.jColorModalShadow"
    :persistent="componentProps.persistent"
    @update:model-value="updateModelValue"
  >
    <v-btn
      v-if="!componentProps.persistent"
      class="pa-0 j-btn j-btn--no-hover j-btn--no-active j-btn--absolute j-btn--close-modal"
      :min-width="32"
      :ripple="false"
      variant="text"
      @click="updateModelValue"
    >
      <v-img
        :width="32"
        :src="images['notification-close']"
        alt="icon notification-close"
      />
    </v-btn>
    <v-card
      :class="[
        'pb-10',
        { 'pt-8': !externalCloseBtn },
      ]"
      :color="bgColor"
      :height="cardHeight"
    >
      <v-card-title class="pa-0">
        <slot name="header" />
      </v-card-title>
      <v-card-text class="pa-0">
        <slot name="body" />
      </v-card-text>
      <v-card-actions
        v-if="!hideFooter"
        class="pa-0 justify-center"
      >
        <slot name="footer" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts" setup>
const componentProps = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  persistent: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [String, Number],
    default: 'auto',
  },
  externalCloseBtn: {
    type: Boolean,
    default: false,
  },
  isCashMobile: {
    type: Boolean,
    default: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
  },
  bgColor: {
    type: String,
    default: '',
  },
});
const emit = defineEmits([
  'update:modelValue',
]);
const updateModelValue = () => emit('update:modelValue', false);
const bgColor = computed(() => componentProps.bgColor || scssVariables.jColorBgMain);
const images = useAssetsImages();
const scssVariables = useScssVariables();
const cardHeight = computed(() => componentProps.isCashMobile
  ? '100%'
  : 'initial');
</script>

<style lang="scss" scoped>
.j-modal {
  .v-overlay__scrim {
    opacity: 0.7;
  }
  :deep(.j-modal__content) {
    margin: 16px;
    > .v-card {
      box-shadow: 0px 24px 176px 0px rgba(0, 0, 0, 0.10) !important;
      border-radius: 10px !important;
    }
  }
}
</style>
