<template>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pt-8 pb-6 px-8 px-lg-6 rounded"
  >
    <nuxt-link to="/promo/statuses">
      <v-img
        class="mx-auto"
        :width="108"
        :lazy-src="playerStatusIconSrc"
        :src="playerStatusIconSrc"
        alt="icon status"
      />
    </nuxt-link>
    <general-text
      :text="playerStatusText"
      class="mt-4"
    />
    <v-progress-linear
      :model-value="getUserData.nextStatusProgress"
      class="mt-2"
      rounded
      rounded-bar
      :color="progressLinerColor"
      :bg-color="scssVariables.jColorDivider"
      bg-opacity="1"
      height="8"
    />
    <div class="d-flex mt-2">
      <general-text
        :text="getUserData.levelStatus"
        class="j-text--white text-capitalize font-weight-medium"
      />
      <v-spacer />
      <general-text
        :text="getUserData.nextLevelStatus"
        class="j-text--white text-capitalize font-weight-medium"
      />
    </div>
  </v-sheet>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { USER_STATUS_PROGRESS_LINER_COLORS } from '../constants';

const { t } = useI18n();
const images = useAssetsImages();
const scssVariables = useScssVariables();
const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const playerStatusIconSrc = computed(() => images[`player_status_${getUserData.value.levelStatus}`]);
const playerStatusText = computed(() => `${t('general_status')}: ${getUserData.value.nextStatusProgress}%`);
const progressLinerColor = computed(
  () => scssVariables[USER_STATUS_PROGRESS_LINER_COLORS[getUserData.value.levelStatus || 'new']],
);
</script>
