<template>
  <v-sheet
    :color="scssVariables.jColorBgBlockDark"
    class="pt-6 pb-4 px-4 pa-md-6"
    rounded
  >
    <v-form
      ref="securityForm"
      :disabled="loading"
      class="j-security-form"
      validate-on="submit"
      @submit.prevent
    >
      <general-text
        dictionary-key="reset_change_password"
        class="j-text--white j-text--fs-16 font-weight-medium mb-6 mb-md-8 j-security-form__w-md-38p"
      />
      <general-text-field
        v-model.trim="securityData.oldPassword"
        :label="$t('cabinet_security_old_password')"
        :type="oldPasswordFieldType"
        :append-inner-icon="oldPasswordFieldAppendIcon"
        :append-inner-icon-color="appendInnerIconColorOld"
        class="mb-4 mb-md-6 j-security-form__w-md-38p"
        :error-messages="errorMessageOldPassword"
        :rules="[
          required,
          password,
          passwordMinLength,
          passwordMaxLength,
          passwordForbiddenSymbols,
        ]"
        @update:model-value="clearMessageOldPasswordError"
        @click:append-inner="toggleOldPasswordVisibility"
      />
      <div class="d-flex flex-column flex-md-row">
        <general-text-field
          v-model.trim="securityData.password"
          :label="$t('cabinet_security_new_password')"
          :type="passwordFieldType"
          :append-inner-icon="passwordFieldAppendIcon"
          :append-inner-icon-color="appendInnerIconColor"
          class="mr-md-12 j-security-form__w-md-38p"
          :error-messages="errorMessagePassword"
          :rules="[
            required,
            password,
            passwordMinLength,
            passwordMaxLength,
            passwordForbiddenSymbols,
            passwordMatched(securityData.password, newPassword),
          ]"
          @update:model-value="clearMessagePasswordError"
          @click:append-inner="togglePasswordVisibility"
        />
        <general-text-field
          v-model.trim="newPassword"
          :label="$t('cabinet_security_new_password_again')"
          :type="newPasswordFieldType"
          :append-inner-icon="newPasswordFieldAppendIcon"
          :append-inner-icon-color="appendInnerIconColorNew"
          class="j-security-form__w-md-38p"
          :rules="[required]"
          @click:append-inner="toggleNewPasswordVisibility"
        />
      </div>
      <v-divider
        :color="scssVariables.jColorDivider"
        class="border-opacity-50 mt-8 mb-6"
      />
      <general-switch
        :model-value="getUserData.mfaEnabled"
        :ripple="false"
        class="mb-8 mb-md-14"
        max-width="max-content"
        @click="switchMFAHandler"
      >
        <general-text
          dictionary-key="cabinet_security_turn_on_two_fa"
          class="j-text--white j-text--fs-12"
        />
      </general-switch>
      <v-btn
        :loading="loading"
        variant="flat"
        size="large"
        :color="scssVariables.jColorBtnRegular"
        class="j-btn text-none text-body-1 px-6"
        height="54"
        :width="isXsDisplay ? '100%' : '132px'"
        @click="submitSecurityForm"
      >
        {{ $t('general_save') }}
      </v-btn>
    </v-form>
    <general-modal
      v-model="showTwoFAModal"
      width="418"
      @update:model-value="cancelModalHandler"
    >
      <template #header>
        <general-text
          :dictionary-key="getModalTitle"
          class="px-6 px-sm-7 mb-6 text-center j-text--fs-24 j-text--white text-wrap"
        />
      </template>
      <template #body>
        <div v-if="!getUserData.mfaEnabled">
          <general-text
            dictionary-key="cabinet_security_modal_subtitle"
            class="px-7 px-sm-8 mb-4 text-center text-wrap j-text--white j-text--fs-12"
          />
          <div class="d-flex px-8 mb-5 justify-center">
            <v-btn
              :color="scssVariables.jColorBtnRegular"
              variant="outlined"
              bg-color="transparent"
              size="large"
              height="32"
              width="106"
              target="_blank"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              class="text-none text-body-1 px-3 mr-1"
            >
              <div class="d-flex">
                <v-img
                  :src="images['apple']"
                  :width="18"
                  alt="icon appstore"
                />
                <div class="ml-2">
                  <general-text
                    dictionary-key="cabinet_security_modal_apple_available"
                    class="j-text--fs-8"
                  />
                  <general-text
                    text="App Store"
                    class="j-text--fs-10 font-weight-bold"
                  />
                </div>
              </div>
            </v-btn>
            <v-btn
              :color="scssVariables.jColorBtnRegular"
              variant="outlined"
              bg-color="transparent"
              size="large"
              height="32"
              width="106"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              class="text-none text-body-1 px-3 ml-1"
            >
              <div class="d-flex">
                <v-img
                  :src="images['playmarket']"
                  :width="19"
                  alt="icon playmarket"
                />
                <div class="ml-2">
                  <general-text
                    dictionary-key="cabinet_security_modal_google_available"
                    class="j-text--fs-8"
                  />
                  <general-text
                    text="Google Play"
                    class="j-text--fs-10 font-weight-bold"
                  />
                </div>
              </div>
            </v-btn>
          </div>
          <general-text
            dictionary-key="cabinet_security_modal_description"
            class="j-text--fs-12 px-4 px-sm-14 text-center"
          />
        </div>
        <v-img
          :src="getQrImgSrc"
          :width="120"
          :class="[
            'rounded-lg',
            'mx-auto',
            'mb-7',
            getUserData.mfaEnabled ? 'mt-5' : 'mt-6',
            'j-security-form__modal-img',
          ]"
          alt="2FA Auth QR Code"
          @click="copy2FACode"
        />
        <v-form
          ref="securityFormModal"
          validate-on="submit"
          @submit.prevent
        >
          <general-text-field
            v-model.trim="code"
            placeholder="_ _ _ _ _ _"
            type="password"
            :label="$t('cabinet_security_modal_google_authenticator_code')"
            class="mb-6 px-4 px-sm-14"
            :error-messages="errorMessageCode"
            :rules="[
              required,
              mfa,
            ]"
            @update:model-value="clearMessageCodeError"
          />
        </v-form>
      </template>
      <template #footer>
        <div class="d-flex px-4 px-sm-14 justify-space-between w-100">
          <v-btn
            :color="scssVariables.jColorBtnRegular"
            max-width="117"
            width="40%"
            variant="flat"
            size="large"
            height="54"
            class="j-btn text-none text-body-1 px-6 px-lg-5"
            @click="cancelModalHandler"
          >
            {{ $t('general_cancel') }}
          </v-btn>
          <v-btn
            :loading="loadingModalConfirmBtn"
            :color="scssVariables.jColorPrimary"
            :max-width="isMobileMode ? 171 : 175"
            width="58%"
            variant="flat"
            size="large"
            height="54"
            type="submit"
            class="j-btn text-none text-body-1 px-6 px-lg-5"
            @click="confirmModalHandler"
          >
            {{ $t('general_confirm') }}
          </v-btn>
        </div>
      </template>
    </general-modal>
  </v-sheet>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useCabinetStore } from '~/stores/cabinet';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import type { VuetifyForm } from '~/types/general/vuetify';
import type { ResponseError } from '~/types/general/globals';
import type { SecurityData } from '~/types/pages/cabinet';

const securityForm: Ref<null | VuetifyForm> = ref(null);
const securityFormModal: Ref<null | VuetifyForm> = ref(null);
const oldPasswordFieldState = usePasswordVisibility();
const passwordFieldState = usePasswordVisibility();
const newPasswordFieldState = usePasswordVisibility();
const {
  appendInnerIconColor: appendInnerIconColorOld,
  passwordFieldType: oldPasswordFieldType,
  passwordFieldAppendIcon: oldPasswordFieldAppendIcon,
  togglePasswordVisibility: toggleOldPasswordVisibility,
} = oldPasswordFieldState;
const {
  appendInnerIconColor,
  passwordFieldType,
  passwordFieldAppendIcon,
  togglePasswordVisibility,
} = passwordFieldState;
const {
  appendInnerIconColor: appendInnerIconColorNew,
  passwordFieldType: newPasswordFieldType,
  passwordFieldAppendIcon: newPasswordFieldAppendIcon,
  togglePasswordVisibility: toggleNewPasswordVisibility,
} = newPasswordFieldState;
const images = useAssetsImages();
const scssVariables = useScssVariables();
const loading = ref(false);
const cabinetStore = useCabinetStore();
const {
  changePassword,
  mfaActivate,
  mfaValidate,
  mfaDeactivate,
} = cabinetStore;
const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const getModalTitle = computed(() => {
  return getUserData.value.mfaEnabled
    ? 'cabinet_security_modal_title_disable'
    : 'cabinet_security_modal_title_enable';
});
const getQrImgSrc = computed(() => {
  return getUserData.value.mfaEnabled
    ? images['mfa-qr-stub']
    : mfaData.value.qrCode;
});
const switchMFAHandler = async () => {
  if (!getUserData.value.mfaEnabled) {
    try {
      const response = await mfaActivate();

      if (response) {
        mfaData.value = response;
      }
    } catch (error) {
      throw(error);
    }
  }
  showTwoFAModal.value = true;
};
const cancelModalHandler = () => {
  code.value = '';
  mfaData.value = {
    mfaCode: '',
    qrCode: '',
  };
  clearMessageCodeError();
  showTwoFAModal.value = false;
};
const mfaData = ref({
  mfaCode: '',
  qrCode: '',
});
const confirmModalHandler = async () => {
  const isValidForm = await securityFormModal.value?.validate();

  if (isValidForm?.valid) {
    loadingModalConfirmBtn.value = true;
    try {
      if (getUserData.value.mfaEnabled) {
        await mfaDeactivate(code.value);
        setNotification({
          dictionaryKeyTitle: 'cabinet_security_notification_title_disable',
          dictionaryKeyText: 'cabinet_security_notification_description_disable',
        });
      } else {
        await mfaValidate(code.value);
        setNotification({
          dictionaryKeyTitle: 'cabinet_security_notification_title_enable',
          dictionaryKeyText: 'cabinet_security_notification_description_enable',
        });
      };
      cancelModalHandler();
    } catch (error) {
      const responseError = error as ResponseError;

      switch (responseError?.data?.return?.errorCode) {
        case 4030:
          errorMessageCode.value = t('error_mfa_not_valid');
          break;
        default:
          clearMessageCodeError();
      }
    }
    loadingModalConfirmBtn.value = false;
  }
};
const code = ref('');
const loadingModalConfirmBtn = ref(false);
const globalsStore = useGlobalsStore();
const { setNotification } = globalsStore;
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const newPassword = ref('');
const showTwoFAModal = ref(false);
const copy2FACode = () => {
  navigator.clipboard.writeText(mfaData.value.mfaCode);
};
const securityData: Ref<SecurityData> = ref({
  oldPassword: '',
  password: '',
});
const { t } = useI18n();
const {
  required,
  password,
  passwordMinLength,
  passwordMaxLength,
  passwordForbiddenSymbols,
  passwordMatched,
  mfa,
} = useValidationRules();

const changePasswordHandler = async () => {
  const isValidForm = await securityForm.value?.validate();

  if (isValidForm?.valid) {
    try {
      await changePassword(securityData.value);
      setNotification({ dictionaryKeyText: 'cabinet_security_your_password_been_changed' });
    } catch (error) {
      const responseError = error as ResponseError;

      switch (responseError.data?.return?.errorCode) {
        case 4003:
          errorMessageOldPassword.value = t('cabinet_security_error_4003');
          break;
        case 4004:
          errorMessagePassword.value = t('cabinet_security_error_4004');
          break;
        default:
          errorMessageOldPassword.value = '';
          errorMessagePassword.value = '';
      }
    }
  }
};
const errorMessagePassword = ref('');
const errorMessageOldPassword = ref('');
const errorMessageCode = ref('');
const submitSecurityForm = async () => {
  const isValidForm = await securityForm.value?.validate();

  if (isValidForm?.valid) {
    loading.value = true;
    await changePasswordHandler();
    loading.value = false;
  }
};
const clearMessagePasswordError = () => errorMessagePassword.value = '';
const clearMessageOldPasswordError = () => errorMessageOldPassword.value = '';
const clearMessageCodeError = () => errorMessageCode.value = '';
</script>
<style lang="scss" scoped>
.j-security-form {
  .j-security-form__w-42p {
    max-width: 42%;
    width: 100%;
  }
  @media(min-width: 960px) {
    .j-security-form__w-md-34p {
      max-width: 34%;
      width: 100%;
    }
    .j-security-form__w-md-38p {
      max-width: 38%;
      width: 100%;
    }
    .j-security-form__w-md-53p {
      max-width: 53%;
      width: 100%;
    }
  }
  &__modal-img {
    cursor: pointer;
  }
}
</style>
