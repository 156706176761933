<template>
  <j-page-cabinet-two-columns>
    <template #left>
      <j-page-cabinet-parts-personal-data-form />
    </template>
    <template #right>
      <j-page-cabinet-parts-player-status />
    </template>
  </j-page-cabinet-two-columns>
</template>
