<template>
  <v-sheet color="transparent">
    <div class="j-games-history-filter d-flex flex-column flex-sm-row mb-4 mb-sm-6">
      <div class="j-games-history-filter__select">
        <general-select
          v-model="gamesHistoryParams.gameId"
          :items="lastMonthPlayedGames"
          item-title="title"
          item-value="value"
          class="w-100"
          @update:model-value="loadGamesHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_games') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
      <div class="j-games-history-filter__select">
        <general-select
          v-model="gamesHistoryParams.currency"
          :items="currencies"
          class="w-100"
          @update:model-value="loadGamesHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_currency') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
    </div>
    <general-table
      :headers="TABLES_ITEMS.gamesHistory.headers"
      :items="formattedGamesHistory"
    />
    <general-pagination
      v-model="gamesHistoryParams.page"
      :total="getGamesHistoryPagination.total"
      :limit="gamesHistoryParams.limit"
      class="mt-8"
      @update:model-value="loadGamesHistory"
    />
  </v-sheet>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { TABLES_ITEMS } from '~/components/JPage/Cabinet/constants';
import { FORMAT_DATE } from '~/constants/general';
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';

const { t } = useI18n();
const cabinetStore = useCabinetStore();
const {
  fetchGamesHistory,
  fetchLastMonthPlayedGames,
} = cabinetStore;
const {
  getGamesHistoryItems,
  getGamesHistoryPagination,
  getLastMonthPlayedGames,
} = storeToRefs(cabinetStore);
const globalsStore = useGlobalsStore();
const { fetchCurrencies } = globalsStore;
const { getCurrencies } = storeToRefs(globalsStore);

const gamesHistoryParams = ref({
  gameId: null,
  currency: '',
  page: 1,
  limit: 10,
});

const lastMonthPlayedGames = computed(() => {
  const lastMonthPlayedGamesFormatted = getLastMonthPlayedGames.value.map((game) => ({
    title: game.fullName,
    value: game.gameId,
  }));

 return [
  {
    title: t('general_all'),
    value: null,
  },
  ...lastMonthPlayedGamesFormatted,
 ];
});

const currencies = computed(() => {
  const currenciesFormatted = getCurrencies.value.map((currency) => ({
    title: currency.coin,
    value: currency.coin,
  }));

  return [
    {
      title: t('general_all'),
      value: '',
    },
    ...currenciesFormatted,
  ];
});

const formattedGamesHistory = computed(() => {
  return getGamesHistoryItems.value.map((item) => {

    return {
      startDate: useFormatDate(item.startDate, FORMAT_DATE.DOT_DATE),
      gameName: item.gameName,
      betAmount: `${item.betAmount} ${item.currency}`,
      winAmount: `${item.winAmount} ${item.currency}`,
      balance: `${item.balance} ${item.currency}`,
    };
  });
});
const loadGamesHistory = async () => {
  const paramsObj = { ...gamesHistoryParams.value };

  for (const key in paramsObj) {
    if (paramsObj.hasOwnProperty(key)
    && (paramsObj[key as keyof typeof paramsObj] === '' || paramsObj[key as keyof typeof paramsObj] === null)) {
      delete paramsObj[key as keyof typeof paramsObj];
    }
  }

  await fetchGamesHistory(paramsObj);
};
const loadGamesHistoryFirstPage = () => {
  gamesHistoryParams.value.page = 1;
  loadGamesHistory();
};

onMounted(() => {
  nextTick(async () => {
    await loadGamesHistory();
    await fetchLastMonthPlayedGames();
    await fetchCurrencies();
  });
});
</script>
<style scoped lang="scss">
.j-games-history-filter {
  gap: 8px;
  @media (min-width: 600px) {
    gap: 16px;
  }
}
.j-games-history-filter__select {
  min-width: 160px;
  :deep(.v-select__selection) {
    white-space: nowrap;
    span {
      color: $j-color-text-secondary;
    }
  }
  @media (min-width: 600px) {
    min-width: 220px;
  }
}
</style>
