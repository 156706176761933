<template>
  <div class="j-cabinet-two-columns flex-column-reverse flex-lg-row d-flex justify-sm-space-between">
    <div class="j-cabinet-two-columns__left">
      <slot name="left" />
    </div>
    <div class="j-cabinet-two-columns__right mb-4 mb-lg-0">
      <slot name="right" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .j-cabinet-two-columns {
    &__left,
    &__right {
      flex: 0 0 100%;
      max-width: 100%;
    }
    &__left {
      @media(min-width: 1280px) {
        flex: 0 0 64.6644%;
        max-width: 64.6644%;
      }
    };
    &__right {
      @media(min-width: 1280px) {
        flex: 0 0 32.5090%;
        max-width: 32.5090%;
      }
    }
  }
</style>