<template>
  <div class="mx-6">
    <v-img
      v-if="getContentCardImage"
      :lazy-src="getContentCardImage.content"
      :src="getContentCardImage.content"
      alt="promo-card-content"
      class="mx-auto pb-6 pt-10"
      max-width="84"
    />
  </div>
  <div class="j-promo-card-content mb-6 ml-6 mr-1 pr-1">
    <div
      v-for="(data, id) of contentPromoCard"
      :key="id"
    >
      <general-text
        v-if="
          (data.content || data.content === 0)
            && (data.type === PROMO_CARD_CONTENT_TYPES.title || data.type === PROMO_CARD_CONTENT_TYPES.text)"
        :text="data.content"
        :class="[
          'my-1',
          {
            'j-text--white j-text--fs-16 font-weight-medium mb-3' : data.type === PROMO_CARD_CONTENT_TYPES.text,
          },
        ]"
      />
      <div
        v-if="typeof data.content === 'object' && data.content !== null && !Array.isArray(data.content)
          && Object.keys(data.content) && (data.type === PROMO_CARD_CONTENT_TYPES.titleAndText)"
        class="'my-1 mb-3 d-flex align-center"
      >
        <general-text :text="data.content.title" />
        <general-text
          :text="data.content.text"
          class="j-text--white j-text--fs-16 font-weight-medium ml-1"
        />
      </div>
      <general-sanitized-html-container
        v-else-if="(data.content || data.content === 0) && data.type === PROMO_CARD_CONTENT_TYPES.html"
        :html-string="data.content"
        class="my-1"
      />
      <nuxt-link
        v-else-if="data.type === PROMO_CARD_CONTENT_TYPES.link"
        :to="useLocalePrefixForHref(data.content.url)"
        class="j-link j-link--main-gradient mb-2"
      >
        {{ data.content.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PROMO_CARD_CONTENT_TYPES } from '~/constants/general';
import type { PromotionCard } from '~/types/pages/cabinet';

const props = defineProps({
  contentPromoCard: {
    type: Object,
    default: () => ({}),
  },
});

const getContentCardImage = computed(() =>
  props.contentPromoCard.find((data: PromotionCard) => data.type === PROMO_CARD_CONTENT_TYPES.image));
</script>

<style lang="scss" scoped>
.j-promo-card-content {
  height: 170px;
  overflow-y: auto;
  @include custom-scrollbar($j-color-primary, $j-color-bg-block-light);
}
</style>
