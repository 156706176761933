<template>
  <v-sheet color="transparent">
    <div class="j-transaction-history-filter d-flex mb-4 mb-sm-6 flex-wrap">
      <div class="j-transaction-history-filter__datepicker">
        <general-datepicker
          v-model="transactionHistoryParams.date"
          :prefix="`${$t('general_date')}:`"
          multiple
          @update:model-value="loadTransactionHistoryFirstPage"
        />
      </div>
      <div class="j-transaction-history-filter__select">
        <general-select
          v-model="transactionHistoryParams.type"
          :items="transactionTypesForSelect"
          class="w-100"
          @update:model-value="loadTransactionHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_transactions') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
      <div class="j-transaction-history-filter__select">
        <general-select
          v-model="transactionHistoryParams.currency"
          :items="currencies"
          class="w-100"
          @update:model-value="loadTransactionHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_currency') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
      <div class="j-transaction-history-filter__select j-transaction-history-filter__select--w-328">
        <general-select
          v-model="transactionHistoryParams.status"
          :items="transactionStatusesForSelect"
          class="w-100"
          @update:model-value="loadTransactionHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_transaction_status') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
    </div>
    <general-table
      :headers="TABLES_ITEMS.transactionHistory.headers"
      :items="formattedTransactionHistory"
      @action="openCancelingModal"
    />
    <general-pagination
      v-model="transactionHistoryParams.page"
      :total="getTransactionHistoryPagination.total"
      :limit="transactionHistoryParams.limit"
      class="mt-8"
      @update:model-value="loadTransactionHistory"
    />
  </v-sheet>
  <general-modal
    v-model="showCancelingTransactionModal"
    width="412"
  >
    <template #header>
      <general-text
        dictionary-key="general_сonfirm_action"
        class="px-7 mb-4 text-center j-text--fs-24 j-text--white text-wrap"
      />
    </template>
    <template #body>
      <general-text
        dictionary-key="cabinet_transaction_history_modal_description"
        class="px-8 mb-10 text-center text-wrap j-text--white"
      />
    </template>
    <template #footer>
      <v-btn
        :color="scssVariables.jColorBtnRegular"
        width="117"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="closeCancelingModal"
      >
        {{ $t('general_cancel') }}
      </v-btn>
      <v-btn
        :loading="loadingModalConfirmBtn"
        :color="scssVariables.jColorPrimary"
        width="175"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="confirmTransactionCanceling"
      >
        {{ $t('general_yes') }}
      </v-btn>
    </template>
  </general-modal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  TABLES_ITEMS,
  TRANSACTION_TYPES,
  TRANSACTION_STATUSES,
} from '~/components/JPage/Cabinet/constants';
import { FORMAT_DATE } from '~/constants/general';
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';

const { t } = useI18n();
const scssVariables = useScssVariables();
const cabinetStore = useCabinetStore();
const {
  fetchTransactionHistory,
  cancelTransaction,
} = cabinetStore;
const {
  getTransactionHistoryItems,
  getTransactionHistoryPagination,
} = storeToRefs(cabinetStore);
const globalsStore = useGlobalsStore();
const { fetchCurrencies } = globalsStore;
const { getCurrencies } = storeToRefs(globalsStore);
const currentTransaction = ref<{transactionId?: string}>({});
const showCancelingTransactionModal = ref(false);
const loadingModalConfirmBtn = ref(false);
const transactionHistoryParams = ref({
  type: '',
  status: '',
  currency: '',
  date: [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 12),
    new Date(),
  ],
  page: 1,
  limit: 10,
});

const transactionTypesForSelect = computed(() => {
  const transactionTypesFormatted = Object.keys(TRANSACTION_TYPES).map((type) => ({
    title: t(`cabinet_transaction_history_${type}`),
    value: TRANSACTION_TYPES[type as keyof typeof TRANSACTION_TYPES],
  }));

  return [
    {
      title: t('general_all'),
      value: '',
    },
    ...transactionTypesFormatted,
  ];
});

const transactionStatusesForSelect = computed(() => {
  const transactionStatusesFormatted = Object.keys(TRANSACTION_STATUSES).map((status) => ({
    title: t(`cabinet_transaction_history_${TRANSACTION_STATUSES[status as keyof typeof TRANSACTION_STATUSES]}`),
    value: TRANSACTION_STATUSES[status as keyof typeof TRANSACTION_STATUSES],
  }));

  return [
    {
      title: t('general_all'),
      value: '',
    },
    ...transactionStatusesFormatted,
  ];
});

const currencies = computed(() => {
  const currenciesFormated = getCurrencies.value.map((currency) => ({
    title: currency.coin,
    value: currency.coin,
  }));

  return [
    {
      title: t('general_all'),
      value: '',
    },
    ...currenciesFormated,
  ];
});

const formatDate = (date: Date) => {
  return useFormatDate(String(new Date(new Date(date).setHours(12))), FORMAT_DATE.ISO_DATE);
};

const extractDate = (dateParams: Date[]) => {
  const [startDate, endDate] = dateParams;

  return {
    from: formatDate(startDate),
    to: formatDate(endDate || startDate),
  };
};

const formattedTransactionHistory = computed(() => {
  return getTransactionHistoryItems.value.map((item) => {
    let contentClass = '';

    switch(item.status) {
      case TRANSACTION_STATUSES.ok:
        contentClass = 'green';
        break;
      case TRANSACTION_STATUSES.inProgress:
      case TRANSACTION_STATUSES.init:
        contentClass = 'orange';
        break;
      case TRANSACTION_STATUSES.failure:
        contentClass = 'error';
        break;
      default:
        contentClass = 'secondary';
    }

    return {
      date: useFormatDate(item.date),
      type: t(`cabinet_transaction_history_${item.type.toLowerCase()}`),
      system: item.system,
      transactionId: item.transactionId,
      amount: {
        value: item.amount,
        contentClass,
        ...(item.compensation && {
          tooltip: t('cabinet_transaction_history_tooltip', {
            compensation: item.compensation,
            currency: item.currency,
          }),
        }),
      },
      status: {
        value: t(`cabinet_transaction_history_${item.status.toLowerCase()}`),
        contentClass,
        ...((item.type === TRANSACTION_TYPES.withdrawal
          && [TRANSACTION_STATUSES.reject, TRANSACTION_STATUSES.init]
          .includes(item.status)) && {
            button: {
              value: t('general_cancel'),
              contentClass: 'error',
            },
          }
        ),
      },
    };
  });
});

const loadTransactionHistory = async () => {
  const {
    date,
    ...otherProps
  } = transactionHistoryParams.value;

  for (const key in otherProps) {
    if (otherProps.hasOwnProperty(key) && otherProps[key as keyof typeof otherProps] === '') {
      delete otherProps[key as keyof typeof otherProps];
    }
  }

  const dateQueries = extractDate(date);
  const queries = {
    ...dateQueries,
    ...otherProps,
  };

  await fetchTransactionHistory(queries);
};

const loadTransactionHistoryFirstPage = () => {
  transactionHistoryParams.value.page = 1;

  loadTransactionHistory();
};

const openCancelingModal = (event: object) => {
  currentTransaction.value = event;
  showCancelingTransactionModal.value = true;
};

const closeCancelingModal = () => {
  showCancelingTransactionModal.value = false;
  currentTransaction.value = {};
};

const confirmTransactionCanceling = async () => {
  if (currentTransaction.value.transactionId) {
    loadingModalConfirmBtn.value = true;
    try {
      await cancelTransaction(currentTransaction.value.transactionId);
      await loadTransactionHistory();
      closeCancelingModal();
    } catch (error) {
      throw(error);
    }
    loadingModalConfirmBtn.value = false;
  }
};

onMounted(() => {
  nextTick(async () => {
    await loadTransactionHistory();
    await fetchCurrencies();
  });
});
</script>
<style scoped lang="scss">
.j-transaction-history-filter {
  gap: 8px;
  @media (min-width: 600px) {
    gap: 16px;
  }
}
.j-transaction-history-filter__select {
  width: 100%;
  :deep(.v-select__selection span) {
    color: $j-color-text-secondary;
  }
  @media (min-width: 600px) {
    width: initial;
    min-width: 220px;
  }
}
.j-transaction-history-filter__select--w-328 {
  width: 100%;
  @media (min-width: 600px) {
    width: initial;
  }
}
.j-transaction-history-filter__datepicker {
  min-width: 100%;
  @media (min-width: 600px) {
    min-width: 258px;
  }
}
</style>
