<template>
  <client-only>
    <div
      v-if="htmlString"
      v-dompurify-html="formattedHtml"
      class="j-sanitized-html-container"
    />
  </client-only>
</template>
<script lang="ts" setup>
const props = defineProps({
  htmlString: {
    type: String,
    default: '',
    required: true,
  },
});

const formattedHtml = computed(() => {
  const tempElement = document.createElement('div');

  tempElement.innerHTML = props.htmlString;
  const tables = tempElement.querySelectorAll('table');

  tables.forEach((table) => {
    const wrapperDiv = document.createElement('div');

    wrapperDiv.className = 'j-sanitized-html-container__table';
    table.parentNode?.replaceChild(wrapperDiv, table);
    wrapperDiv.appendChild(table);
  });

  return tempElement.innerHTML;
});
</script>
<style lang="scss">
.j-sanitized-html-container {
  p {
    margin: 8px 0;
    word-break: break-word
  }
  ul {
    padding-left: 14px;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: $j-color-text-regular;
    transition: 0.3s;
    background: $j-gradient-main-text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &:hover {
      opacity: 0.8;
    }
  }
}
.j-sanitized-html-container__table {
  overflow-x: auto;
  table {
    border-spacing: 0px 4px;
    line-height: normal;
    border: transparent;
    width: 100% !important
    ;
  }
  thead {
    background-color: $j-color-bg-block-dark;
  }
  th {
    height: 32px;
    border-bottom: none;
    padding: 8px 24px;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  th,
  td {
    text-align: start;
    width: 1%;
  }
  tbody tr {
    background-color: $j-color-btn-regular;
  }
  tbody td {
    border-bottom: none;
    height: 40px;
    padding: 12px 24px;
    word-break: break-word;
    &:first-child {
      padding-top: 12px;
    }
    &:last-child {
      padding-bottom: 12px;
    }
  }
}
</style>
