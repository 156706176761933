<template>
  <div class="j-carousel d-flex flex-column align-center">
    <v-btn
      v-if="showArrows"
      variant="flat"
      size="large"
      min-width="auto"
      class="j-btn j-btn--slider j-btn--carousel-arrows j-btn--carousel-arrow-left"
      :width="44"
      :color="scssVariables.jColorBtnRegular"
      :disabled="disablePrevBtn"
      @click="goToPrevSlide()"
    >
      <v-icon
        :size="30"
        icon="mdi-chevron-left"
        :color="colorIconPrevBtn"
      />
    </v-btn>

    <v-carousel
      v-model="activeSlide"
      :cycle="cycle"
      :show-arrows="false"
      hide-delimiters
      :height="height"
    >
      <template
        v-for="(slide, slideIndex) in slideList"
        :key="slideIndex"
      >
        <v-carousel-item>
          <div :class="['d-flex flex-nowrap', { 'px-14': showArrows }]">
            <div
              v-if="emptyText"
              class="j-carousel__empty-text text-center"
            >
              <general-text
                :text="emptyText"
                class="j-text--fs-24 j-text--white"
              />
            </div>
            <div
              v-for="(item, itemIndex) in slide"
              :key="itemIndex"
              :class="[
                { 'px-4': slide.length > 1 },
                'j-carousel__slide-item',
              ]"
            >
              <slot
                v-if="checkingEmptyCarouselItem(item as FormattedPromotionData)"
                name="carousel-item"
                :item="item"
              />
            </div>
          </div>
        </v-carousel-item>
      </template>
    </v-carousel>
    <v-btn
      v-if="showArrows"
      variant="flat"
      size="large"
      min-width="auto"
      class="j-btn j-btn--slider j-btn--carousel-arrows j-btn--carousel-arrow-right"
      :width="44"
      :color="scssVariables.jColorBtnRegular"
      :disabled="disableNextBtn"
      @click="goToNextSlide()"
    >
      <v-icon
        :size="30"
        icon="mdi-chevron-right"
        :color="colorIconNextBtn"
      />
    </v-btn>
    <!--TODO: use native carousel delimiters-->
    <general-carousel-parts-delimiter-buttons
      v-if="!hideDelimiters"
      :delimiters-amount="itemsList?.length ?? 0"
      :active-delimiter="activeSlide"
      :change-slide="changeSlide"
      class="my-6 my-lg-8"
    />
  </div>
</template>

<script setup lang="ts">
import type { FormattedPromotionData } from '~/types/pages/cabinet';

const scssVariables = useScssVariables();
const activeSlide = ref(0);
const propsComponent = defineProps({
  showArrows: {
    type: Boolean,
    default: false,
  },
  hideDelimiters: {
    type: Boolean,
    default: false,
  },
  itemsList: {
    type: Array,
    default: () => [],
  },
  height: {
    type: String,
    default: 'auto',
  },
  itemsPerSlide: {
    type: Number,
    default: 1,
  },
  cycle: {
    type: Boolean,
    default: false,
  },
  numberColumns: {
    type: Number,
    default: null,
  },
  emptyText: {
    type: String,
    default: '',
  },
});
const changeSlide = (index: number) => activeSlide.value = index;
const totalSlidesAmount = computed(() => {
  return Math.ceil(propsComponent.itemsList?.length / propsComponent.itemsPerSlide);
});
const goToNextSlide = () => {
  activeSlide.value = Math.min(activeSlide.value + 1, totalSlidesAmount.value - 1);
};
const goToPrevSlide = () => {
  activeSlide.value = Math.max(activeSlide.value - 1, 0);
};
const disablePrevBtn = computed(() => !Boolean(activeSlide.value));
const disableNextBtn = computed(() =>
  !Boolean(totalSlidesAmount.value) ||
  (activeSlide.value === totalSlidesAmount.value - 1),
);
const colorIconPrevBtn = computed(() => disablePrevBtn.value
  ? scssVariables.jColorTextSecondary
  : '');
const colorIconNextBtn = computed(() => disableNextBtn.value
  ? scssVariables.jColorTextSecondary
  : '');
const slideList = computed(() => {
  let slides = [];

  for (let i = 0; i < propsComponent.itemsList?.length; i += propsComponent.itemsPerSlide) {
    let slide = propsComponent.itemsList?.slice(i, i + propsComponent.itemsPerSlide);

    while (slide?.length < propsComponent.itemsPerSlide) {
      slide.push({});
    }
    slides.push(slide);
  }

  return slides;
});
const checkingEmptyCarouselItem = (item: FormattedPromotionData) => Boolean(Object.keys(item).length > 0);
</script>

<style scoped lang="scss">
.j-carousel {
  position: relative;
}
.j-carousel__slide-item {
  flex: 1 1 0%;
}
.j-carousel__empty-text {
  opacity: .7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 355px;
}
</style>
