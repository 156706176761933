<template>
  <v-responsive
    :max-width="componentProps.maxWidth"
    :class="[
      'j-switch',
      {
        'j-switch--no-ripple': !componentProps.ripple,
      },
    ]"
  >
    <v-switch
      hide-details="auto"
      inset
      :label="componentProps.label"
      :model-value="modelValue"
      :type="componentProps.type"
      :error-messages="componentProps.errorMessages"
      :ripple="componentProps.ripple"
      @update:model-value="updateModelValue"
    >
      <template
        v-if="!componentProps.label"
        #label
      >
        <slot />
      </template>
    </v-switch>
  </v-responsive>
</template>

<script setup lang="ts">
import type { Density } from '~/types/components/General/TextField';

const componentProps = defineProps({
  label: {
    type: String,
    default: '',
  },
  density: {
    type: String as () => Density,
    default: 'default' as Density,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'string',
  },
  ripple: {
    type: Boolean,
    default: true,
  },
  errorMessages: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: [String, Number],
    default: '100%',
  },
});
const emit = defineEmits([
  'update:modelValue',
]);

const updateModelValue = (modelValue: boolean | null) => emit('update:modelValue', modelValue);
</script>

<style scoped lang="scss">
.j-switch {
  :deep(.v-input) {
    .v-label {
      font-size: $j-form-label-font-size;
      width: auto;
      opacity: 1;
      padding-inline-start: 8px;
    }
    .v-selection-control {
      min-height: auto;
    }
    .v-selection-control__wrapper {
      height: auto;
    }
    .v-selection-control__input {
      transform: translateX(-5px);
    }
    .v-switch__track {
      min-width: 32px;
      border-radius: 100px;
      height: 20px;
      border: 1px solid $j-color-bg-block-light;
      background-color: transparent;
      opacity: 1;
    }
    .v-switch__thumb {
      width: 14px;
      height: 14px;
      transform: none;
      box-shadow: none;
      background-color: $j-color-text-secondary;
    }
    .v-selection-control--dirty {
      .v-selection-control__input {
        transform: translateX(5px);
      }
      .v-switch__track {
        background: $j-gradient-btn-main;
        border: 0;
      }
      .v-switch__thumb {
        background-color: $j-color-text-regular;
      }
    }
  }
  &--no-ripple:deep(.v-input) {
    .v-selection-control__input {
      &::before {
        content: none;
      }
    }
  }
}
</style>
