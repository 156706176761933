<template>
  <v-sheet
    border
    :color="scssVariables.jColorBgMain"
    class="px-6 pt-6 pb-8 mx-auto cabinet_balance_promocode"
    :max-width="isMobileMode ? '368px' : 'initial'"
    rounded
  >
    <general-text
      dictionary-key="cabinet_balance_promocode_title"
      class="j-text--white font-weight-medium"
    />
    <v-form
      ref="bonusCodeForm"
      class="d-flex mt-4"
      validate-on="submit"
      @submit.prevent
    >
      <general-text-field
        v-model.trim="bonusCodeData.bonusCode"
        class="flex-shrink-1"
        :label="$t('cabinet_balance_promocode_placeholder')"
        :rules="[bonusCode]"
        :error-messages="errorMessageBonusCodeForm"
        @update:model-value="clearBonusCodeFormError"
      />
      <v-btn
        variant="flat"
        size="large"
        :color="scssVariables.jColorPrimary"
        class="text-none text-body-1 px-3 j-btn ml-2 j-balance-promo-code__btn"
        :loading="loading"
        height="42"
        :disabled="!bonusCodeData.bonusCode"
        @click="submitBonusCode"
      >
        {{ $t('cabinet_balance_promocode_activate') }}
      </v-btn>
    </v-form>
  </v-sheet>
</template>

<script setup lang="ts">
import type { VuetifyForm } from '~/types/general/vuetify';
import type { ResponseError } from '~/types/general/globals';
import { PLATFORM_BY_DEVICE_TYPE } from '~/constants/general';
import { LOCAL_STORAGE } from '~/constants/storedPropertiesNames';
import { BONUS_TYPES } from '~/components/JPage/Cabinet/constants';
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';

const globalsStore = useGlobalsStore();
const {
  setNotification,
  startFreeSpinsPolling,
} = globalsStore;
const scssVariables = useScssVariables();
const loading = ref(false);
const bonusCodeData = ref({ bonusCode: '' });
const errorMessageBonusCodeForm = ref('');
const { bonusCode } = useValidationRules();
const bonusCodeForm: Ref<null | VuetifyForm> = ref(null);
const cabinetStore = useCabinetStore();
const { t } = useI18n();
const { activateBonus } = cabinetStore;
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
/**
 * Submits the bonus code for activation. This function validates the bonus code form,
 * checks the activation cache to prevent reactivation within 10 seconds, and processes
 * the activation if valid. If free spins are present in the activated bonus, it triggers
 * the notification for free spins along with additional related logic.
 */
const submitBonusCode = async () => {
  const isValidForm = await bonusCodeForm.value?.validate();
  const currentTime = Date.now();
  const bonusCode = bonusCodeData.value.bonusCode;
  const bonusActivationCache = useLocalStorageObject.get(LOCAL_STORAGE.bonusActivationCache) || {};
  const activationTimeBonusCache = bonusActivationCache[bonusCode];

  if (activationTimeBonusCache && (currentTime - activationTimeBonusCache < 10000)) {
    return;
  }

  if (isValidForm?.valid) {
    loading.value = true;
    try {
      const activatedBonus = await activateBonus(bonusCodeData.value);
      const bonusesNumber = activatedBonus['rewardUtilizationProgress'].absoluteBonusSum;
      const showFreeSpins = activatedBonus['rewardUtilizationProgress'].type === BONUS_TYPES.freeRounds;

      if (showFreeSpins) {
        await startFreeSpinsPolling(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
      } else if (bonusesNumber) {
        setNotification({
          dictionaryKeyText: 'cabinet_balance_promocode_bonus_added_bonuses',
          dictionaryKeyTextProps: {
            count: bonusesNumber,
            currency: activatedBonus.currency,
          },
        });
      }
      bonusActivationCache[bonusCode] = currentTime;
      useLocalStorageObject.set(LOCAL_STORAGE.bonusActivationCache, bonusActivationCache);
      errorMessageBonusCodeForm.value = '';
    } catch (error) {
      const responseError = error as ResponseError;
      const errorCode = (
        responseError?.data?.return?.errorCode &&
        responseError.data.return.errorCode.toString().toLowerCase()
      );

      switch(errorCode) {
        case 'promotion_currency_mismatch':
          const currency = responseError?.data?.return?.errors && responseError.data.return.errors[0];

          errorMessageBonusCodeForm.value = t(`cabinet_balance_promocode_${errorCode}`, { currency });
          break;
        default:
          errorMessageBonusCodeForm.value = t(`cabinet_balance_promocode_${errorCode}`);
      }
    }
    loading.value = false;
  }
};
const clearBonusCodeFormError = () => errorMessageBonusCodeForm.value = '';
</script>

<style lang="scss" scoped>
.j-balance-promo-code {
  border-color: $j-color-btn-regular;
}
.j-balance-promo-code__btn {
  margin-top: 22px;
}
</style>
