<template>
  <j-page-cabinet-two-columns class="pb-sm-13">
    <template #left>
      <j-page-cabinet-parts-balance-currencies />
    </template>
    <template #right>
      <j-page-cabinet-parts-player-status />
      <j-page-cabinet-parts-balance-promo-code class="mt-4" />
    </template>
  </j-page-cabinet-two-columns>
  <v-divider class="my-8 my-sm-16 j-divider-full-width" />
  <j-page-cabinet-parts-balance-promotions-block class="pt-sm-13 pb-sm-6" />
  <v-divider class="mt-2 mb-8 my-sm-16 j-divider-full-width" />
  <j-page-cabinet-parts-balance-rewards-history />
</template>

<style lang="scss" scoped>
.j-divider-full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
</style>
