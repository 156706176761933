export const USER_STATUS_PROGRESS_LINER_COLORS = {
  new: 'jColorGreen',
  player: 'jColorProgressBarStatusPlayer',
  silver: 'jColorSilverGrey',
  gold: 'jColorProgressBarStatusGold',
  vip: 'jColorPrimary',
};

export const AVAILABLE_COUNTRIES = [
  'AT',
  'AZ',
  'AS',
  'AI',
  'AO',
  'HR',
  'AD',
  'AQ',
  'AG',
  'AR',
  'AM',
  'BS',
  'BD',
  'BB',
  'BY',
  'BJ',
  'BM',
  'BO',
  'BA',
  'BW',
  'VG',
  'BF',
  'BI',
  'VU',
  'VA',
  'VI',
  'GA',
  'HT',
  'GY',
  'GM',
  'GH',
  'GP',
  'GT',
  'GW',
  'GN',
  'GG',
  'GI',
  'HN',
  'HK',
  'GD',
  'GL',
  'GR',
  'CD',
  'JE',
  'DJ',
  'DO',
  'DM',
  'DE',
  'ZM',
  'EH',
  'ZW',
  'IN',
  'JO',
  'IE',
  'IS',
  'YE',
  'CV',
  'KZ',
  'KY',
  'CM',
  'CA',
  'KE',
  'KI',
  'CC',
  'KM',
  'CG',
  'XK',
  'CI',
  'KG',
  'LA',
  'LS',
  'LR',
  'LI',
  'LU',
  'MU',
  'MG',
  'YT',
  'MO',
  'MW',
  'MY',
  'MV',
  'MA',
  'MQ',
  'MH',
  'MX',
  'FM',
  'MZ',
  'MD',
  'MC',
  'MN',
  'MS',
  'MM',
  'NA',
  'NR',
  'NG',
  'NE',
  'AN',
  'NI',
  'NU',
  'NC',
  'CK',
  'TC',
  'CP',
  'IM',
  'NF',
  'CX',
  'MF',
  'HM',
  'PW',
  'PS',
  'PG',
  'PY',
  'PE',
  'PN',
  'PL',
  'PT',
  'PR',
  'MK',
  'RE',
  'RU',
  'RW',
  'WS',
  'SM',
  'ST',
  'SZ',
  'SH',
  'MP',
  'SC',
  'BL',
  'PM',
  'SN',
  'VC',
  'KN',
  'LC',
  'RS',
  'SK',
  'SI',
  'SB',
  'SR',
  'SL',
  'TJ',
  'TW',
  'TZ',
  'TL',
  'TG',
  'TK',
  'TO',
  'TV',
  'TN',
  'UG',
  'UZ',
  'WF',
  'UY',
  'FO',
  'FJ',
  'PH',
  'PF',
  'TF',
  'CF',
  'TD',
  'CL',
  'SJ',
  'EC',
  'GQ',
  'AX',
  'SV',
  'ER',
  'GS',
  'KR',
  'JM',
  'FI',
  'NO',
  'CZ',
  'NZ',
];

export const VERIFICATION = {
  identityConfirmation: [
    {
      title: 'cabinet_verification_identity_confirmation_title',
      subtitle: 'cabinet_verification_important',
      list: 'cabinet_verification_identity_confirmation_list',
    },
  ],
  paymentMethodConfirmation: [
    {
      title: 'cabinet_verification_payment_method_confirmation_title',
      subtitle: 'cabinet_verification_important',
    },
  ],
  paymentMethodConfirmationSub: [
    {
      title: 'cabinet_verification_deposit_from_yours_card_title',
      list: 'cabinet_verification_deposit_from_yours_card_list',
    },
    {
      title: 'cabinet_verification_not_same_personal_data_title',
      list: 'cabinet_verification_not_same_personal_data_list',
    },
    {
      title: 'cabinet_verification_payment_system_make_deposit_title',
      list: 'cabinet_verification_payment_system_make_deposit_list',
    },
  ],
};

export const DOCUMENT_STATUSES = {
  new: {
    id: 9,
    color: 'gradient',
  },
  inProgress: {
    id: 10,
    color: 'orange',
  },
  success: {
    id: 11,
    color: 'green',
  },
  declined: {
    id: 12,
    color: 'secondary',
  },
};

export const DOCUMENT_TYPES_ID = {
  main: 6,
  card: 7,
  other: 8,
  personal: 17,
};

export const BONUS_TYPES = {
  freeRounds: 'FREE_ROUNDS',
  regularBonus: 'REGULAR_BONUS',
};

export const BONUS_STATUSES = {
  activated: 'ACTIVATED',
  canceled: 'CANCELED',
  awarded: 'AWARDED',
  expired: 'EXPIRED',
  available: 'AVAILABLE',
  error: 'ERROR',
};

export const TRANSACTION_TYPES = {
  deposit: 'deposit',
  withdrawal: 'withdrawal',
  refund: 'refund',
};

export const TRANSACTION_STATUSES = {
  inProgress: 'in_progress',
  reject: 'reject',
  failure: 'failure',
  init: 'init',
  ok: 'ok',
};

export const TABLES_ITEMS = {
  gamesHistory: {
    headers: [
      {
        dictionaryKey: 'general_date',
        fieldName: 'startDate',
      },
      {
        dictionaryKey: 'general_game',
        fieldName: 'gameName',
      },
      {
        dictionaryKey: 'general_bet',
        fieldName: 'betAmount',
      },
      {
        dictionaryKey: 'general_win',
        fieldName: 'winAmount',
      },
      {
        dictionaryKey: 'general_balance',
        fieldName: 'balance',
      },
    ],
  },
  rewardsHistory: {
    headers: [
      {
        dictionaryKey: 'general_actions',
        fieldName: 'action',
      },
      {
        dictionaryKey: 'general_receiving_date',
        fieldName: 'date',
      },
      {
        dictionaryKey: 'general_currency',
        fieldName: 'currency',
      },
      {
        dictionaryKey: 'general_status',
        fieldName: 'status',
      },
    ],
  },
  transactionHistory: {
    headers: [
      {
        dictionaryKey: 'general_date',
        fieldName: 'date',
      },
      {
        dictionaryKey: 'general_operation_type',
        fieldName: 'type',
      },
      {
        dictionaryKey: 'general_system',
        fieldName: 'system',
      },
      {
        dictionaryKey: 'general_sum',
        fieldName: 'amount',
      },
      {
        dictionaryKey: 'general_status',
        fieldName: 'status',
      },
    ],
  },
  bonusesHistory: {
    headers: [
      {
        dictionaryKey: 'general_type',
        fieldName: 'type',
      },
      {
        dictionaryKey: 'general_bonus_sum',
        fieldName: 'sum',
      },
      {
        dictionaryKey: 'general_required_bets',
        fieldName: 'bets',
      },
      {
        dictionaryKey: 'general_received',
        fieldName: 'assignedAt',
      },
      {
        dictionaryKey: 'general_available_until',
        fieldName: 'activeTo',
      },
      {
        dictionaryKey: 'general_state',
        fieldName: 'state',
      },
    ],
  },
};
