<template>
  <div class="d-flex flex-wrap mt-sm-13">
    <general-text
      :text="`${$t('cabinet_profile')},`"
      class="j-text--white j-text--fs-24 j-text--fs-lg-32 mr-2"
    />
    <general-text
      :text="getUserData.screenName || 'user'"
      class="j-text--fs-24 j-text--fs-lg-32"
    />
  </div>
  <general-tabs
    class="mt-lg-10 mb-lg-8 my-6"
    :items="Object.keys(REQUIRED_AUTH_PAGES_WITH_CHILDREN.cabinet)"
    relative-path="cabinet"
  />
  <component :is="Component as string" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';

definePageMeta({ middleware: 'ensure-authenticated' });
const authStore = useAuthStore();
const { getUserData } = storeToRefs(authStore);
const route = useRoute();
const Component = computed(() => {
  switch(route.params.pageType) {
    case 'balance':
      return resolveComponent('JPageCabinetBalance');
    case 'personal-data':
      return resolveComponent('JPageCabinetPersonalData');
    case 'verification':
      return resolveComponent('JPageCabinetVerification');
    case 'games-history':
      return resolveComponent('JPageCabinetGamesHistory');
    case 'transaction-history':
      return resolveComponent('JPageCabinetTransactionHistory');
    case 'bonus-history':
      return resolveComponent('JPageCabinetBonusesHistory');
    case 'security':
      return resolveComponent('JPageCabinetSecurity');
    default: null;
  }
});
</script>
