<template>
  <div
    :class="[
      'j-flip',
      { 'j-flip--active': isActive && isMobileMode },
    ]"
    @click="toggleCard"
  >
    <div class="j-flip__inner">
      <div class="j-flip__front">
        <slot name="front" />
      </div>
      <div class="j-flip__back">
        <slot name="back" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const isMobileMode = inject<Ref<boolean>>('isMobileMode');

const isActive = ref(false);
const toggleCard = () => {
  if (isMobileMode?.value) {
    isActive.value = !isActive.value;
  }
};
</script>

<style lang="scss" scoped>
.j-flip {
  perspective: 1000px;
  overflow: hidden;
  .j-flip__inner {
    display: grid;
    transition: 0.6s;
    transform-style: preserve-3d;
  }
  &:hover {
    @media(min-width: 1280px) {
      .j-flip__inner {
        transform: rotateY(180deg);
      }
    }
  }
  &.j-flip--active {
    .j-flip__inner {
      transform: rotateY(180deg);
    }
  }
  .j-flip__front,
  .j-flip__back {
    grid-area: 1/1;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .j-flip__back {
    transform: rotateY(180deg);
  }
}
</style>
