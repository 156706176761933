<template>
  <v-expansion-panels
    v-model="activeItemIndex"
    class="j-expansion-panels"
    :color="scssVariables.jColorBgBlockDark"
    selected-class="j-expansion-panels--active"
  >
    <v-expansion-panel
      v-for="(item, index) of items"
      :key="index"
      :color="scssVariables.jColorBgBlockDark"
      :bg-color="scssVariables.jColorBgBlockDark"
      :elevation="0"
      class="d-flex flex-column"
    >
      <template
        #title
        v-if="$slots.title"
      >
        <slot name="title" />
      </template>
      <v-expansion-panel-title v-if="!$slots.title">
        <div>
          <general-text
            :dictionary-key="item.title"
            class="j-text--fs-16 j-text--white"
          />
          <general-text
            v-if="item.subtitle"
            :dictionary-key="item.subtitle"
            class="mt-2"
          />
        </div>
        <template #actions>
          <v-btn
            variant="flat"
            :color="getButtonColor(index)"
            :ripple="false"
            min-width="32px"
            height="32px"
            class="j-btn j-btn--no-hover pa-0 j-expansion-panels__btn"
          >
            <v-img
              class="j-expansion-panels__img"
              :src="images['dropdown-Icon']"
              alt="icon dropdown"
              width="16px"
            />
          </v-btn>
        </template>
      </v-expansion-panel-title>
      <template
        #text
        v-if="$slots.text"
      >
        <slot name="text" />
      </template>
      <v-expansion-panel-text v-if="!$slots.text">
        <general-text-list
          v-if="item.list"
          :items-list="addIndexForLocalization(item.list)"
          class="j-text--line-height-24"
          no-items-gap
        />
        <general-text
          v-else
          :dictionary-key="item.text"
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script lang="ts" setup>
import type { ExpansionPanelItem } from '~/types/components/General/ExpansionPanels';

defineProps({
  items: {
    type: Array as PropType<ExpansionPanelItem[]>,
    default: () => [],
    required: true,
  },
  textListItems: {
    type: Boolean,
    default: false,
  },
});
const activeItemIndex = ref(undefined);
const scssVariables = useScssVariables();
const images = useAssetsImages();
const getButtonColor = (index: number) => {
  return activeItemIndex.value === index
    ? scssVariables.jColorBtnGradientMainFallBack
    : scssVariables.jColorBtnRegular;
};
</script>
<style lang="scss" scoped>
.j-expansion-panels {
  :deep(.v-expansion-panel::after) {
    content: none;
  }
  :deep(.v-expansion-panel .v-expansion-panel:not(.v-expansion-panel .v-expansion-panel:first-child)) {
    margin-top: 16px
  }
  :deep(.v-expansion-panel-title) {
    min-height: auto;
    padding: 0;
    line-height: inherit;
  }
  :deep(.v-expansion-panel-title__icon) {
    margin: 0 0 auto 4px;
  }
  :deep(.v-expansion-panel-title--active) {
    .j-expansion-panels__btn {
      background: $j-gradient-btn-main;
    }
    .j-expansion-panels__img {
      transform: rotate(180deg);
    }
  }
  :deep(.v-expansion-panel-title__overlay) {
    opacity: 0;
  }
  :deep(.v-expansion-panel-text__wrapper) {
    padding: 16px 0 0;
  }
  :deep(.v-expansion-panel-text) {
    order: 1;
  }
}
</style>
