<template>
  <v-pagination
    v-if="length > 1"
    :model-value="modelValue"
    aria-label=""
    previous-aria-label=""
    next-aria-label=""
    class="j-pagination"
    :length="length"
    :total-visible="4"
    :color="scssVariables.jColorBtnRegular"
    :size="isXsDisplay ? 'x-small' : 'small'"
    variant="flat"
    :active-color="scssVariables.jColorPrimary"
    @update:model-value="updateModelValue"
  />
</template>
<script setup lang="ts">
const scssVariables = useScssVariables();
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');

const props = defineProps({
  modelValue: {
    type: Number,
    default: 1,
  },
  total: {
    type: Number,
    default: 1,
  },
  limit: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits([
  'update:modelValue',
]);

const updateModelValue = (modelValue: number) => emit('update:modelValue', modelValue);
const length = computed(() => Math.ceil(props.total/props.limit));
</script>
<style scoped lang="scss">
.j-pagination {
  :deep(.v-pagination__item) {
    margin: 0 2.5px;
    .v-btn {
      color: $j-color-text-secondary !important;
      font-size: 16px !important;
    }
    .v-btn[ellipsis='true'] {
      color: $j-color-btn-regular !important;
      font-size: 32px !important;
      background-color: transparent !important;
      letter-spacing: 0;
      .v-btn__overlay {
        opacity: 0;
      }
      .v-btn__content {
        transform: translateY(5px);
      }
    }
  }
  :deep(.v-pagination__item--is-active ) {
    .v-btn {
      color: $j-color-text-regular !important;
      .v-btn__overlay {
        opacity: 0;
      }
    }
  }
  :deep(.v-pagination__prev) {
    margin: 0 41px 0 0;
  }
  :deep(.v-pagination__next) {
    margin: 0 0 0 41px;
  }
}
@media (min-width: 600px) {
  .j-pagination {
    :deep(.v-pagination__item) {
      margin: 0 4px;
      .v-btn[ellipsis='true'] {
        line-height: inherit;
      }
    }
    :deep(.v-pagination__prev) {
      margin: 0 48px 0 0;
    }
    :deep(.v-pagination__next) {
      margin: 0 0 0 48px;
    }
  }
}
</style>