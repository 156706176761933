<template>
  <div class="j-bonuses-and-actions mx-2 mx-sm-0">
    <general-text
      dictionary-key="general_promotions_title"
      class="j-text j-text--white j-text--fs-sm-32 j-text--fs-24 text-center text-sm-left mb-4 mb-sm-16"
    />
    <general-carousel
      ref="carousel"
      :show-arrows="!isXsDisplay"
      :hide-delimiters="!isXsDisplay"
      :items-list="promoCardDataFormatted.length ? promoCardDataFormatted : blankCards"
      :items-per-slide="getItemsPerSlide"
      :empty-text="promoCardDataFormatted.length ? '' : $t('general_no_promotions_available')"
    >
      <template #carousel-item="slotProps">
        <general-promo-card
          v-if="promoCardDataFormatted.length"
          :promo-card-data="(slotProps.item as FormattedPromotionData)"
          @click-verification-control="handleVerificationControl"
          @button-click="toggleAction"
        />
        <div
          v-else
          class="j-bonuses-and-actions__blank-card rounded"
        />
      </template>
    </general-carousel>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import { useCabinetStore } from '~/stores/cabinet';
import { useAuthStore } from '~/stores/auth';
import { useGlobalsStore } from '~/stores/globals';
import { useCashStore } from '~/stores/cash';
import type {
  FormattedPromotionData,
  GamesPromotion,
  PromotionActionData,
  PromotionItem,
} from '~/types/pages/cabinet';
import {
  PLATFORM_BY_DEVICE_TYPE,
  PROMOTION_DATA,
  PROMO_CARD_CONTENT_TYPES,
} from '~/constants/general';
import { BONUS_TYPES } from '~/components/JPage/Cabinet/constants';

const globalsStore = useGlobalsStore();
const { startFreeSpinsPolling } = globalsStore;
const { t } = useI18n();
const images = useAssetsImages();
const isMobileMode = inject<Ref<boolean>>('isMobileMode');
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const getItemsPerSlide = computed(() => {
  return isXsDisplay?.value
    ? 1
    : isMobileMode?.value
      ? 2
      : 4;
});
const blankCards = computed(() => Array(getItemsPerSlide.value).fill(null).map(() => ({ id: Date() })));
const rewardsHistoryParams = ref({
  page: 1,
  limit: 10,
});
const cabinetStore = useCabinetStore();
const authStore = useAuthStore();
const {
  fetchPromotionsData,
  participationAction,
  fetchRewardsHistory,
} = cabinetStore;
const { getPromotions } = storeToRefs(cabinetStore);
const cashStore = useCashStore();
const { activateCash } = cashStore;
const router = useRouter();
const { fetchUserBonusesCount } = authStore;
const toggleAction = (currentAction: FormattedPromotionData) => {
  if (currentAction.actionData.state === PROMOTION_DATA.available) {
    activateAction(currentAction.actionData);
  } else {
    cancelAction(currentAction.actionData.actionId);
  }
};
const handleVerificationControl = async (event: string) => {
  if (event === PROMOTION_DATA.verifyPhoneText) {
    await router.push(useLocalePrefixForHref('/cabinet/personal-data'));
  } else {
    useConfirmationEmail();
  }
};
const cancelAction = async (id: number) => {
  try {
    await participationAction(PROMOTION_DATA.cancel, id);
    await fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
  } catch (error) {
    throw error;
  }
};
const activateAction = async (actionData: PromotionActionData) => {
  try {
    await participationAction(PROMOTION_DATA.join, actionData.actionId);

    await Promise.allSettled([
      fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]),
      fetchUserBonusesCount(),
    ]);

    if (actionData.depositFrom) {
      activateCash();
    } else {
      await fetchRewardsHistory({ ...rewardsHistoryParams.value });

     if (actionData.typeBonus === BONUS_TYPES.freeRounds) {
        await startFreeSpinsPolling(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
     }
    }
  } catch (error) {
    throw error;
  }
};
const promoCardDataFormatted = computed(() =>
  getPromotions.value.map((card) => ({
    front: [
      {
        type: PROMO_CARD_CONTENT_TYPES.image,
        content: card.type_bonus === PROMOTION_DATA.regularBonus ?
          images['bonus'] :
          images['free_spins'],
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.title,
        content: t('general_valid_until'),
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.text,
        content: useFormatDate(card.date_end),
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.title,
        content: card.deposit_from
          ? t('general_condition_receipt')
          : null,
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.text,
        content: card.deposit_from
          ? `${t('general_deposit_from')} ${card.deposit_from} ${card.currency}`
          : null,
      },
    ],
    back: [
      {
        type: PROMO_CARD_CONTENT_TYPES.image,
        content: card.type_bonus === PROMOTION_DATA.regularBonus ?
          images['bonus'] :
          images['free_spins'],
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.titleAndText,
        content: {
          title: t('general_wagering_coefficient'),
          text: card.wager,
        },
      },
      {
        type: PROMO_CARD_CONTENT_TYPES.titleAndText,
        content: {
          title: t('general_wagering_period'),
          text: `${card?.lifetime} ${t('general_days')}`,
        },
      },
      ...getGamesLinks(card.games),
      {
        type: PROMO_CARD_CONTENT_TYPES.html,
        content: card.description,
      },
    ],
    actionData: getActionData(card),
  })),
);
const isActivateBtn = (stateBtn: string) => Boolean(stateBtn === PROMOTION_DATA.available);
const disabledActivateBtn = (phone: boolean, email: boolean, statusCard: string) =>
  Boolean(statusCard === PROMOTION_DATA.available && (phone || email));
const getActionData = (card: PromotionItem) => ({
  actionId: card.id_bonus,
  state: card.state,
  depositFrom: card.deposit_from,
  activateBtn: isActivateBtn(card.state),
  disableBtn: disabledActivateBtn(card.phone, card.mail, card.state),
  showVerificationPhone: card.phone,
  descriptionPhone: 'general_verify_phone',
  showVerificationEmail: card.mail,
  descriptionEmail: 'general_confirm_email',
  typeBonus: card.type_bonus,
});
const getGamesLinks = (games: GamesPromotion[]) =>
  games.map((game) => ({
      type: PROMO_CARD_CONTENT_TYPES.link,
      content: {
        name: game?.name,
        url: `/game/${game?.prettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`,
      },
    }),
  );

onMounted(() => {
  nextTick(async () => {
    await fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
  });
});
</script>

<style scoped lang="scss">
:deep(.j-carousel__arrow) {
  transform: none;
  top: 175px;
}
.j-bonuses-and-actions__blank-card {
  height: 390px;
  background-color: $j-color-bg-block;
}
</style>
