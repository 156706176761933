<template>
  <div>
    <v-sheet
      v-for="(balance, index) of filteredBalances"
      :key="index"
      :color="scssVariables.jColorBgBlockDark"
      :class="[
        'j-balance-currency',
        { 'j-balance-currency--active': balance.active },
      ]"
      class="mb-2 mb-sm-4"
      rounded
    >
      <v-sheet
        :color="scssVariables.jColorBgBlockDark"
        class="pa-6 px-sm-12 py-sm-8"
        rounded
      >
        <div class="d-flex justify-space-between align-center flex-column flex-sm-row">
          <div class="d-flex align-center mb-6 mb-sm-0 mr-auto">
            <v-img
              :src="images[BALANCE_TYPES[balance.type.toLowerCase() as keyof typeof BALANCE_TYPES].toLowerCase()]"
              width="78"
              height="78"
              class="mr-6 mr-sm-9"
              alt="icon currency"
            />
            <div class="d-flex flex-column">
              <general-text :text="`${$t('general_balance')}:`" />
              <general-text
                :text="`${balance.amount} ${balance.currency}`"
                class="j-text--white font-weight-medium j-text--fs-24 j-text--fs-sm-32"
              />
              <general-text :text="`${$t('general_bonuses')}: ${balance.bonusAmount} ${balance.currency}`" />
            </div>
          </div>
          <v-btn
            variant="flat"
            size="large"
            :color="scssVariables.jColorBtnRegular"
            :loading="index === loadingBalanceIndex"
            :height="isXsDisplay ? 40 : 54"
            :class="[
              'j-balance-currency__item-btn',
              'text-none',
              'text-body-1',
              'px-6',
              'j-btn',
              isXsDisplay ? 'w-100' : 'w-auto',
              { 'j-btn--gradient-main': balance.active },
            ]"
            @click="selectCurrency(balance, index)"
          >
            {{ balance.active ? $t('general_selected') : $t('general_select') }}
          </v-btn>
        </div>
      </v-sheet>
    </v-sheet>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { BALANCE_TYPES } from '~/constants/general';
import { useAuthStore } from '~/stores/auth';
import { useCabinetStore } from '~/stores/cabinet';
import { PLATFORM_BY_DEVICE_TYPE } from '~/constants/general';
import type { UserBalanceItem } from '~/types/general/user';

const authStore = useAuthStore();
const cabinetStore = useCabinetStore();
const {
  activateAccount,
  changeActiveCurrency,
  fetchPromotionsData,
} = cabinetStore;
const { getUserData } = storeToRefs(authStore);
const { fetchUserBonusesCount } = authStore;
const scssVariables = useScssVariables();
const images = useAssetsImages();
const filteredBalances = computed(() =>
  getUserData.value.balances?.filter((balance) => balance.type !== BALANCE_TYPES.fun),
);
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
const loadingBalanceIndex: Ref<null | number> = ref(null);
const selectCurrency = async (balance: UserBalanceItem, index: number) => {
  try {
    loadingBalanceIndex.value = index;
    if (!balance.activated) {
      await activateAccount(balance.currency, balance.type);
    }
    await changeActiveCurrency(balance.currency);
    await fetchPromotionsData(PLATFORM_BY_DEVICE_TYPE[useDeviceType()]);
    await fetchUserBonusesCount();
  } catch (error) {
    throw(error);
  } finally {
    loadingBalanceIndex.value = null;
  }
};
</script>

<style lang="scss" scoped>
.j-balance-currency {
  padding: 1px;
}
.j-balance-currency--active {
  background: $j-gradient-btn-main !important;
}
.j-balance-currency__item-btn {
  letter-spacing: initial !important;
}
</style>
