<template>
  <v-sheet color="transparent">
    <general-text
      dictionary-key="cabinet_balance_rewards_history"
      class="j-text j-text--white mb-8 mb-lg-9 j-text--fs-sm-32 j-text--fs-24 text-center text-sm-left"
    />
    <general-table
      :headers="TABLES_ITEMS.rewardsHistory.headers"
      :items="formattedRewardsHistory"
    />
    <general-pagination
      v-model="rewardsHistoryParams.page"
      :total="getRewardsHistoryPagination.total"
      :limit="rewardsHistoryParams.limit"
      class="mt-8"
      @update:model-value="fetchRewardsHistory({ ...rewardsHistoryParams })"
    />
  </v-sheet>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import {
  TABLES_ITEMS,
  BONUS_TYPES,
  BONUS_STATUSES,
} from '~/components/JPage/Cabinet/constants';
import { useCabinetStore } from '~/stores/cabinet';

const { t } = useI18n();
const cabinetStore = useCabinetStore();
const { fetchRewardsHistory } = cabinetStore;
const {
  getRewardsHistoryItems,
  getRewardsHistoryPagination,
} = storeToRefs(cabinetStore);
const rewardsHistoryParams = ref({
  page: 1,
  limit: 10,
});
const formattedRewardsHistory = computed(() => {
  return getRewardsHistoryItems.value.map((item) => {
    let action = '';
    let contentClass = '';

    if (item.type_bonus === BONUS_TYPES.freeRounds) {
      const game = item.games[0];
      const gameLink = `/game/${game.prettyName}/${REQUIRED_AUTH_PAGES_WITH_CHILDREN.game.real}`;

      action = `${item.prize} Free spins: ^:link:^${game.name}^href:^${gameLink}^:link:^`;
    } else {
      action = `Bonus: ${item.prize} ${item.currency}`;
    }

    switch(item.status) {
      case BONUS_STATUSES.activated:
        contentClass = 'green';
        break;
      case BONUS_STATUSES.awarded:
        contentClass = 'primary';
        break;
      case BONUS_STATUSES.available:
        contentClass = 'orange';
        break;
      case BONUS_STATUSES.error:
        contentClass = 'error';
        break;
      default:
        contentClass = 'secondary';
    }

    return {
      action,
      date: useFormatDate(item.date),
      currency: item.currency,
      status: {
       value: t(`cabinet_balance_rewards_history_${item.status.toLowerCase()}`),
       contentClass,
      },
    };
  });
});

onMounted(() => {
  nextTick(async () => {
    await fetchRewardsHistory({ ...rewardsHistoryParams.value });
  });
});
</script>