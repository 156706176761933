<template>
  <v-slide-group
    v-model="tab"
    mandatory
    center-active
    class="j-tabs rounded"
    :class="[
      'j-tabs',
      'rounded',
      { 'j-tabs--light-bg': lightBgColor },
    ]"
    @update:model-value="updateModelValue"
  >
    <v-slide-group-item
      v-for="(param, index) of items"
      :key="index"
      :value="index"
      v-slot="{ toggle }"
    >
      <div
        :class="[
          'j-tabs__item',
          {
            'mx-auto': !center,
            'j-tabs__item--no-mobile-x-padding': mobileXPaddingOff,
          },
        ]"
        @click="toggle"
      >
        <nuxt-link
          v-if="relativePath"
          :to="generateHref(param)"
          :class="[
            'j-link',
            'j-link--tab-item',
            'text-no-wrap',
            'pa-5',
            'd-block',
            'border-b-md',
            'border-opacity-0',
            'h-100',
            {
              'border-opacity-100 j-link--tab-item-active': index === tab,
              'px-7': extendedXPadding,
            },
          ]"
        >
          <general-text
            :dictionary-key="`${relativePath}_${(param as string).replace('-', '_')}`"
            :class="[
              'j-text--white',
              'font-weight-medium',
              { 'j-text--fs-16': !isXsDisplay },
            ]"
          />
        </nuxt-link>
        <v-btn
          v-else
          variant="text"
          :ripple="false"
          :class="[
            'j-btn',
            'j-btn--tab-item',
            'j-btn--no-active',
            'text-none',
            'pa-5',
            'rounded-0',
            'border-b-md',
            'border-opacity-0',
            'h-100',
            {
              'border-opacity-100 j-btn--tab-item-active': index === tab,
              'px-7': extendedXPadding,
            },
          ]"
        >
          <general-text
            :dictionary-key="param"
            :class="[
              'j-text--white',
              'font-weight-medium',
              { 'j-text--fs-16': !isXsDisplay },
            ]"
          />
        </v-btn>
      </div>
    </v-slide-group-item>
  </v-slide-group>
</template>
<script setup lang="ts">
const componentProps = defineProps({
  items: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
  relativePath: {
    type: String,
    default: '',
  },
  extendedXPadding: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  mobileXPaddingOff: {
    type: Boolean,
    default: false,
  },
  lightBgColor: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Number,
    default: null,
  },
});

const route = useRoute();
const tab = ref<number>(0);
const emit = defineEmits([
  'update:modelValue',
]);
const updateModelValue = (modelValue: number) => emit('update:modelValue', modelValue);
const generateHref = (param: string) => {
  return componentProps.modelValue !== null
    ? ''
    : useLocalePrefixForHref(`/${componentProps.relativePath}/${param}`);
};
const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');

onMounted(() => {
  nextTick(() => {
    if (componentProps.modelValue !== null) {
      tab.value = componentProps.modelValue;
    } else {
      const firstRouteParamValue = Array.isArray(Object.values(route.params)[0])
        ? Object.values(route.params)[0][0]
        : Object.values(route.params)[0];

      tab.value = componentProps.items.indexOf(firstRouteParamValue as string);
    }
  });
});
</script>
<style lang="scss" scoped>
.j-tabs {
  height: 56px;
  background-color: $j-color-bg-block-dark;
  :deep(.v-slide-group__content) {
    justify-content: center;
  }
  &.j-tabs--light-bg {
    background-color: $j-color-btn-regular;
  }
}
.j-tabs__item {
  &:not(.j-tabs__item--no-mobile-x-padding) {
    &:first-child {
      padding-left: 28px;
    }
    &:last-child {
      padding-right: 28px;
    }
  }
  @media(min-width: 600px) {
    &:first-child {
    padding-left: 45px;
    }
    &:last-child {
      padding-right: 45px;
    }
  }
}
</style>
