<template>
  <v-sheet color="transparent">
    <div class="j-bonuses-history-filter d-flex mb-4 mb-sm-6">
      <div class="j-bonuses-history-filter__select">
        <general-select
          v-model="bonusesHistoryParams.currency"
          :items="currencies"
          class="w-100"
          @update:model-value="loadBonusesHistoryFirstPage"
        >
          <template #selection="data">
            <span class="mr-1">{{ $t('general_currency') }}:</span> {{ data.item.title }}
          </template>
        </general-select>
      </div>
    </div>
    <general-table
      :headers="TABLES_ITEMS.bonusesHistory.headers"
      :items="formattedBonusesHistory"
      @action="openCancelingModal"
    />
    <general-pagination
      v-model="bonusesHistoryParams.page"
      :total="getBonusesHistoryPagination.total"
      :limit="bonusesHistoryParams.limit"
      class="mt-8"
      @update:model-value="loadBonusesHistory"
    />
  </v-sheet>
  <general-modal
    v-model="showCancelingBonusModal"
    width="412"
  >
    <template #header>
      <general-text
        dictionary-key="general_сonfirm_action"
        class="px-7 mb-4 text-center j-text--fs-24 j-text--white text-wrap"
      />
    </template>
    <template #body>
      <general-text
        dictionary-key="cabinet_bonuses_history_modal_description"
        class="px-8 mb-10 text-center text-wrap j-text--white"
      />
    </template>
    <template #footer>
      <v-btn
        :color="scssVariables.jColorBtnRegular"
        width="117"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="closeCancelingModal"
      >
        {{ $t('general_cancel') }}
      </v-btn>
      <v-btn
        :loading="loadingModalConfirmBtn"
        :color="scssVariables.jColorPrimary"
        width="175"
        variant="flat"
        size="large"
        height="54"
        type="submit"
        class="j-btn text-none text-body-1 px-6 px-lg-5"
        @click="confirmBonusCanceling"
      >
        {{ $t('general_yes') }}
      </v-btn>
    </template>
  </general-modal>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  TABLES_ITEMS,
  BONUS_STATUSES,
} from '~/components/JPage/Cabinet/constants';
import { useCabinetStore } from '~/stores/cabinet';
import { useGlobalsStore } from '~/stores/globals';

const { t } = useI18n();
const scssVariables = useScssVariables();
const cabinetStore = useCabinetStore();
const {
  fetchBonusesHistory,
  cancelBonus,
} = cabinetStore;
const {
  getBonusesHistoryItems,
  getBonusesHistoryPagination,
} = storeToRefs(cabinetStore);
const globalsStore = useGlobalsStore();
const { fetchCurrencies } = globalsStore;
const { getCurrencies } = storeToRefs(globalsStore);
const currentBonus = ref<{bonusId?: number}>({});
const showCancelingBonusModal = ref(false);
const loadingModalConfirmBtn = ref(false);
const bonusesHistoryParams = ref({
  currency: '',
  page: 1,
  limit: 10,
});

const currencies = computed(() => {
  const currenciesFormatted = getCurrencies.value.map((currency) => ({
    title: currency.coin,
    value: currency.coin,
  }));

  return [
    {
      title: t('general_all'),
      value: '',
    },
    ...currenciesFormatted,
  ];
});

const formattedBonusesHistory = computed(() => {
  return getBonusesHistoryItems.value.map((item) => {
    let contentClass = '';

    switch(item.state) {
      case BONUS_STATUSES.activated:
        contentClass = 'green';
        break;
      case BONUS_STATUSES.awarded:
        contentClass = 'primary';
        break;
      case BONUS_STATUSES.available:
        contentClass = 'orange';
        break;
      case BONUS_STATUSES.error:
        contentClass = 'error';
        break;
      default:
        contentClass = 'secondary';
    }

    return {
      bonusId: item.bonus_id,
      type: t('general_bonus'),
      sum: `${item.sum} ${item.currency}`,
      bets: item.bets,
      assignedAt: useFormatDate(item.assigned_at),
      activeTo: useFormatDate(item.active_to),
      state: {
        value: t(`cabinet_balance_rewards_history_${item.state.toLowerCase()}`),
        contentClass,
        ...((item.state === BONUS_STATUSES.activated || item.state === BONUS_STATUSES.available) && {
          button: {
            value: t('general_cancel'),
            contentClass: 'error',
          },
        }),
      },
    };
  });
});

const loadBonusesHistory = async () => {
  const paramsObj = { ...bonusesHistoryParams.value };

  for (const key in paramsObj) {
    if (paramsObj.hasOwnProperty(key) && paramsObj[key as keyof typeof paramsObj] === '') {
      delete paramsObj[key as keyof typeof paramsObj];
    }
  }

  await fetchBonusesHistory(paramsObj);
};

const loadBonusesHistoryFirstPage = () => {
  bonusesHistoryParams.value.page = 1;
  loadBonusesHistory();
};

const openCancelingModal = (event: object) => {
  currentBonus.value = event;
  showCancelingBonusModal.value = true;
};

const closeCancelingModal = () => {
  showCancelingBonusModal.value = false;
  currentBonus.value = {};
};

const confirmBonusCanceling = async () => {
  if (currentBonus.value.bonusId) {
    loadingModalConfirmBtn.value = true;
    try {
      await cancelBonus(currentBonus.value.bonusId);
      await loadBonusesHistory();
      closeCancelingModal();
    } catch (error) {
      throw(error);
    }
    loadingModalConfirmBtn.value = false;
  }
};

onMounted(() => {
  nextTick(async () => {
    await loadBonusesHistory();
    await fetchCurrencies();
  });
});
</script>
<style scoped lang="scss">
.j-bonuses-history-filter__select {
  min-width: 160px;
  :deep(.v-select__selection span) {
    color: $j-color-text-secondary;
  }
  @media (min-width: 600px) {
    min-width: 220px;
  }
}
</style>
